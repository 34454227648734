import React, { useLayoutEffect, useState } from "react";
import { SubLine, SwiperSlides } from "../components/Comps";
import FormContact from "../components/mini_componentes/FormContact";
import { seccionHeight } from "../theme/variables";

import { Helmet } from "react-helmet";

import "./Servicios.css";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const Servicios = () => {
    const [sectionHeight, setSectionHeight] = useState(0);
    const {t} = useTranslation();

    useLayoutEffect(() => {
        setSectionHeight(seccionHeight());
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return <div>
        <Helmet>
            <title>{t('service.page_name')}</title>
            <meta name="description" content="Software a medida, páginas web, aplicaciones móviles multiplataforma, diseño, marketing y facebook ADS. asesorias, capacitaciones, soporte técnico y mantenimiento, son algunos de nuestros servicios | DATA Soluciones en Software" />
            <link rel="canonical" href="https://serviciosdata.com/servicios" />
        </Helmet>
        <div className="seccion seccion-sup seccion-color-2 servicios-seccion-1">
            <h1 className="titulo-servicios-pagina">{t('service.title')}</h1>
            <SubLine />

        </div>
        <SwiperSlides />
        <FormContact />

    </div>

}
export default Servicios; 