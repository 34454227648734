import React, { useEffect, useLayoutEffect, useState } from "react";
import { addClassName, getDate, post, removeClassName, seccionHeight, toastError } from "../../theme/variables";
import { Input, Notificacion } from "../Comps";

import * as EmailValidator from 'email-validator';
import "./FormContact.css";

import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { madePostAppWrite } from "../../common/post";

let FormContact = () => {
    const [sectionHeight, setSectionHeight] = useState(0);
    const [enviarBoton, setEnviarBoton] = useState(false);
    const [formulario, setFormulario] = useState([] as HTMLInputElement[]);

    const { t } = useTranslation();

    const _config = {
        apiKey: "AIzaSyCUkzB3535_Lg8GeC1yZDL0cz6vlgcx-L8",
        authDomain: "data-soluciones.firebaseapp.com",
        databaseURL: "https://data-soluciones-default-rtdb.firebaseio.com",
        projectId: "data-soluciones",
        storageBucket: "data-soluciones.appspot.com",
        messagingSenderId: "379060611119",
        appId: "1:379060611119:web:7cd00322e27466a45d7eda",
        measurementId: "G-P7GS5HS5WH"
    };

    useLayoutEffect(() => {
        setSectionHeight(seccionHeight());

        let formularioState = () => {
            let nombre = document.getElementById("nombre_datos-form") as HTMLInputElement;
            let cargo = document.getElementById("cargo_datos-form") as HTMLInputElement;
            let organizacion = document.getElementById("organizacion_datos-form") as HTMLInputElement;
            let correo = document.getElementById("correo_datos-form") as HTMLInputElement;
            let telefono = document.getElementById("telefono_datos-form") as HTMLInputElement;
            let asunto = document.getElementById("asunto_datos-form") as HTMLInputElement;
            let detalles = document.getElementById("detalles_datos-form") as HTMLInputElement;

            let inps = [nombre, cargo, organizacion, correo, telefono, asunto, detalles];

            setFormulario(inps);

            let isCorrect = () => {
                let nums = [inps[0].value.length, inps[1].value.length, inps[2].value.length, inps[3].value.length, inps[4].value.length, inps[5].value.length, inps[6].value.length];
                //Cuantos tienen >0
                let exist = 0;
                for (let i = 0; i < nums.length; i++) {
                    if (nums[i] > 0) {
                        exist++;
                    }
                }
                if (exist === nums.length) {
                    //Si todos los campos tiene al menos >0
                    return true;
                } else {
                    return false;
                }
                return false;
            }

            for (let i = 0; i < inps.length; i++) {
                let corr = () => {
                    if (isCorrect()) {
                        setEnviarBoton(true);
                    } else {
                        setEnviarBoton(false);
                    }
                }
                inps[i].onkeyup = corr;
                inps[i].onpaste = corr;
                inps[i].oninput = corr;
            }
        }
        formularioState();

    }, []);

    let enviarEmail = (datos: {}) => {
        for (let i = 0; i < formulario.length; i++) {
            formulario[i].value = "";
        }

        setEnviarBoton(false);

        document.getElementById("notificacion-contactos-1")!.style.display = "block";
        setTimeout(() => {
            document.getElementById("notificacion-contactos-1")!.style.display = "block";
        }, 2000);

        post("contactos.php", datos, {}).then(resp => {
            //console.log(resp);
        }).catch(err => { })

        madePostAppWrite(
            datos,
            "forms",
            "form-contact-work"
        )
    }

    let datosForm = () => {
        let nombre = document.getElementById("nombre_datos-form") as HTMLInputElement;
        let cargo = document.getElementById("cargo_datos-form") as HTMLInputElement;
        let organizacion = document.getElementById("organizacion_datos-form") as HTMLInputElement;
        let correo = document.getElementById("correo_datos-form") as HTMLInputElement;
        let telefono = document.getElementById("telefono_datos-form") as HTMLInputElement;
        let asunto = document.getElementById("asunto_datos-form") as HTMLInputElement;
        let detalles = document.getElementById("detalles_datos-form") as HTMLInputElement;
        return {
            clave: "form-contactos",
            organizacion: organizacion.value,
            cargo: cargo.value,
            nombre: nombre.value,
            asunto: asunto.value,
            detalles: detalles.value,
            correo: correo.value,
            telefono: telefono.value,
            fecha: getDate()
        }
    }

    let correoValido = () => {
        let correo = document.getElementById("correo_datos-form") as HTMLInputElement;
        return EmailValidator.validate(correo.value);
    }

    useEffect(() => {
        if (!enviarBoton) {
            addClassName("boton-dark-little", "disabled");
        } else {
            removeClassName("boton-dark-little", "disabled");
        }
    }, [enviarBoton])

    return <div className="seccion seccion-regular seccion-regular-formulario" style={{ minHeight: sectionHeight + "px", backgroundColor: "var(--gray-1)", padding: "3rem 1rem"}}>



        <form action='https://forms.zohopublic.com/datasolucionesensoftware/form/Contact/formperma/OCpWNV02QFU8uLkFoIsDO7GYWyA5pWrCFvsDU0DwSmI/htmlRecords/submit' name='form' method='POST' accept-charset='UTF-8' encType='multipart/form-data' id="formulario">
            <input type="hidden" name="zf_referrer_name" value="" />
            {/* <!-- To Track referrals , place the referrer name within the " " in the above hidden input field --> */}
            <input type="hidden" name="zf_redirect_url" value="" />
            {/* <!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field --> */}
            <input type="hidden" name="zc_gad" value="" />
            {/* <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM --> */}
            <span className="slogan-contactos-down" style={{textAlign: "center", width: "100%", display: "block"}}>{t('contact_form_work.subtitle')}</span>
            <h1 className="title-contactos-down">{t('contact_form_work.title')}</h1>

            <div className="formulario-contactos">
                <Input name="SingleLine" id="nombre_datos-form" group="datos" title={t('contact_form_work.inputs.input_1.placeholder')} color="dark" />

                <Input name="SingleLine1" id="cargo_datos-form" group="datos" title={t('contact_form_work.inputs.input_2.placeholder')} color="dark" />

                <Input name="SingleLine2" id="organizacion_datos-form" group="datos" title={t('contact_form_work.inputs.input_3.placeholder')} color="dark" />

                <Input name="Email" id="correo_datos-form" group="datos" title={t('contact_form_work.inputs.input_4.placeholder')} color="dark" />

                <Input name="SingleLine3" id="telefono_datos-form" group="datos" title={t('contact_form_work.inputs.input_5.placeholder')} color="dark" />

                <Input name="SingleLine4" id="asunto_datos-form" group="datos" title={t('contact_form_work.inputs.input_6.placeholder')} color="dark" />

                <Input name="SingleLine5" id="detalles_datos-form" group="datos" title={t('contact_form_work.inputs.input_7.placeholder')} color="dark" />
            </div>

            <Notificacion texto={t('contact_form_work.notifications.on_submit')} id="notificacion-contactos-1" />
            <button className={"boton-dark-little"}
                type={"submit"}
                style={{margin: "0 auto", display: "block"}}
                onClick={async () => {
                    //Enviar solo si tiene los campos llenos
                    if (enviarBoton && correoValido()) {
                        //Enviar EMAIL
                        let datos = datosForm();
                        enviarEmail(datos);
                    } else if (!correoValido()) {
                        toastError(t('contact_form_work.notifications.on_submit_error.invalid_email', { returnObjects: false }) as string);
                    }
                }}>{t('contact_form_work.btn_submit.text')}</button>

        </form>
    </div>

}
export default FormContact;