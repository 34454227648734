import { FirebaseDatabaseNode } from "@react-firebase/database";
import React from "react";
import PDFReader from "../components/mini_componentes/PDFReader";
import { seccionHeight } from "../theme/variables";

import "./Certificado.css";

class Certificado extends React.Component<{}, { curso: string, certificadoNro: string, archivo: string, canUpdate: boolean,seccionHeight: number}>{
    constructor(props: any) {
        super(props);
        this.state = {curso: "", certificadoNro:"", archivo: "", canUpdate: true, seccionHeight: 0};
    }
    componentDidMount(){
        let codigoGrl = window.location.pathname.split("/")[2];   
        if(codigoGrl !== undefined && codigoGrl !== null && codigoGrl !== ""){
            this.setState({curso: codigoGrl.split("-")[0], certificadoNro: codigoGrl.split("-")[1]})
        }else{
            this.setState({curso: "---", certificadoNro: "---"});
        }

        this.setState({ seccionHeight: seccionHeight() });
    }

    render(){
        return <FirebaseDatabaseNode
                path={"certificados/"+this.state.curso}
                orderByValue={"created_on"}
            >
        {(d: { [x: string]: { [x: string]: any; }; })=>{
            if(this.state.certificadoNro !== null  && this.state.curso !== null){
                if(d["value"]!== null && d["value"][this.state.certificadoNro] !==null && d["value"][this.state.certificadoNro] !==undefined){
                    if(this.state.canUpdate){
                        this.setState({archivo: d["value"][this.state.certificadoNro], canUpdate: false}, ()=>{console.log("--")});
                    }
                }
            }
            return <div>
                {this.state.archivo !== ""?
                    <div className="certificado-container" style={{minHeight: this.state.seccionHeight+"px"}} >
                        <PDFReader archivo={this.state.archivo}/> 
                        <a href={this.state.archivo} download={"Certificado_"+this.state.curso+"-"+this.state.certificadoNro+".pdf"}><button>DESCARGAR CERTIFICADO</button></a>
                    </div>
                    :""
                }
            </div>
        }}
        </FirebaseDatabaseNode>
    }
}
export default Certificado;