export let rompecabezas = <svg version="1.1" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
<g id="mision-ct" transform="matrix(0 .2636 -.2636 0 129 -24.429)">
 <path d="m111.77 457.39c0 8.742 6.8688 15.611 15.61 15.611 158.49-0.0166 185.06-0.0424 330.77-0.0424 8.742 0 15.138-8.0758 15.138-16.817v-133.62h-9.991c-5.619 0-11.239 1.8741-14.985 5.6201-8.118 7.493-19.357 11.864-31.22 12.488-26.849 0.624-50.576-21.23-51.2-48.079-0.624-28.098 21.854-50.576 49.327-51.2 12.488 0 23.727 4.9954 32.468 12.488 4.371 3.746 9.9898 6.2442 15.61 6.2442h9.991v-134.24c0-8.742-6.8688-15.61-15.61-15.61h-134.87v-9.9897c0-6.244 2.4982-11.239 6.2442-15.61 8.117-8.741 12.487-19.98 12.487-32.468 0-27.473-22.477-49.952-50.575-49.328-26.849 0.62399-48.704 24.351-47.455 51.2 0 11.864 4.9954 23.104 12.488 31.221 3.747 4.37 5.6201 9.3645 5.6201 14.985v9.9897h-134.24c-8.742 0-15.61 6.8688-15.61 15.61v331.55z" style={{fill:"#f15450"}}/>
</g>
<g id="vision-ct" transform="matrix(-.2636 0 0 -.2636 224.73 129.4)">
 <path d="m111.77 457.39c0 8.742 6.8688 15.611 15.61 15.611 158.49-0.0166 185.06-0.0424 330.77-0.0424 8.742 0 15.138-8.0758 15.138-16.817v-133.62h-9.991c-5.619 0-11.239 1.8741-14.985 5.6201-8.118 7.493-19.357 11.864-31.22 12.488-26.849 0.624-50.576-21.23-51.2-48.079-0.624-28.098 21.854-50.576 49.327-51.2 12.488 0 23.727 4.9954 32.468 12.488 4.371 3.746 9.9898 6.2442 15.61 6.2442h9.991v-134.24c0-8.742-6.8688-15.61-15.61-15.61h-134.87v-9.9897c0-6.244 2.4982-11.239 6.2442-15.61 8.117-8.741 12.487-19.98 12.487-32.468 0-27.473-22.477-49.952-50.575-49.328-26.849 0.62399-48.704 24.351-47.455 51.2 0 11.864 4.9954 23.104 12.488 31.221 3.747 4.37 5.6201 9.3645 5.6201 14.985v9.9897h-134.24c-8.742 0-15.61 6.8688-15.61 15.61v331.55z" style={{fill:"#f0800f"}}/>
</g>
<g id="valores-ct" transform="matrix(0 -.2636 .26317 0 45.338 235.31)">
 
  <path transform="matrix(0 1.0037 -1.0037 0 892.7 -172.25)" d="m166.66 379.26h60.963v-9.9531c0-6.2207 2.4887-11.196 6.2207-15.551 8.0867-8.7084 12.439-19.906 12.439-32.348 0-27.37-22.394-49.766-50.387-49.145-26.749 0.62165-48.522 24.261-47.277 51.01 0 11.82 4.9764 23.019 12.441 31.105 3.733 4.3537 5.5996 9.3287 5.5996 14.928v9.9531m-134.48 0.0117c-8.7094-0.0179-15.553 6.8444-15.553 15.553v329.07c0 8.7094 6.8444 15.553 15.553 15.553h691.41c8.7094 0 15.553-6.8444 15.553-15.553-0.0165-157.89-0.043-184.37-0.043-329.54 0-8.7094-8.0455-15.082-16.754-15.082h-133.12v9.9531c0 5.598 1.8676 11.198 5.5996 14.93 7.465 8.0877 11.82 19.285 12.441 31.104 0.62167 26.749-21.152 50.386-47.9 51.008-27.993 0.62167-50.386-21.772-51.008-49.143 0-12.441 4.9764-23.637 12.441-32.346 3.732-4.3547 6.2207-9.9537 6.2207-15.553v-9.9531l-287.2 0.25764h-79.409z" style={{fill:"#9bbd28", strokeWidth: ".99627"}}/>
 
</g>
    <g>
        <g id="mision-txt" transform="scale(.26458)" aria-label="MISIÓN">
        <path d="m113.97 147.13q-0.53333 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.827q0-0.58667 0.32-0.90667 0.37334-0.37333 0.90667-0.37333h2.6667q0.63999 0 0.95999 0.32t0.42667 0.53333l11.253 21.547 11.36-21.547q0.10667-0.21333 0.37333-0.53333 0.32-0.32 0.96-0.32h2.6133q0.58666 0 0.90666 0.37333 0.37333 0.32 0.37333 0.90667v34.827q0 0.53333-0.37333 0.90666-0.32 0.32-0.90666 0.32h-2.6133q-0.53333 0-0.90666-0.32-0.32-0.37333-0.32-0.90666v-26.24l-8.9067 17.387q-0.21334 0.53333-0.64 0.85333-0.42667 0.26667-1.0667 0.26667h-1.6533q-0.69333 0-1.0667-0.26667-0.37333-0.32-0.64-0.85333l-8.96-17.387v26.24q0 0.53333-0.37334 0.90666-0.31999 0.32-0.85333 0.32z" style={{fill:"#fff"}}/>
        <path d="m156.32 147.13q-0.58666 0-0.90666-0.32-0.32-0.37333-0.32-0.90666v-34.88q0-0.53334 0.32-0.85334 0.32-0.37333 0.90666-0.37333h2.8267q0.58666 0 0.90666 0.37333 0.32 0.32 0.32 0.85334v34.88q0 0.53333-0.32 0.90666-0.32 0.32-0.90666 0.32z" style={{fill:"#fff"}}/>
        <path d="m181.65 147.67q-4.64 0-7.7867-1.44-3.1467-1.44-4.8533-3.68-1.6533-2.2933-1.76-4.8 0-0.42667 0.32-0.74667 0.37333-0.37333 0.85333-0.37333h2.72q0.64 0 0.96 0.32t0.42666 0.74667q0.32 1.28 1.3333 2.56 1.0133 1.2267 2.88 2.08 1.92 0.8 4.9067 0.8 4.5867 0 6.72-1.6 2.1867-1.6533 2.1867-4.3733 0-1.8667-1.1733-2.9867-1.12-1.1733-3.4667-2.08-2.3467-0.90667-6.08-1.9733-3.8933-1.12-6.4533-2.4533-2.56-1.3333-3.84-3.3067-1.2267-1.9733-1.2267-5.0133 0-2.88 1.5467-5.12 1.5467-2.2933 4.48-3.6267t7.04-1.3333q3.3067 0 5.76 0.90666 2.5067 0.85334 4.16 2.3467 1.6533 1.44 2.5067 3.1467 0.85333 1.6533 0.90667 3.2533 0 0.37334-0.26667 0.74667-0.26667 0.32-0.85333 0.32h-2.8267q-0.37333 0-0.8-0.21333-0.37333-0.21334-0.58666-0.8-0.32-2.1867-2.4533-3.68-2.1333-1.4933-5.5467-1.4933-3.4667 0-5.6533 1.3333-2.1333 1.3333-2.1333 4.16 0 1.8133 1.0133 3.04 1.0133 1.1733 3.2 2.08 2.1867 0.90667 5.7067 1.92 4.2667 1.1733 6.9867 2.5067 2.72 1.28 4 3.2533 1.3333 1.92 1.3333 4.96 0 3.36-1.76 5.76-1.76 2.3467-4.96 3.6267-3.1467 1.2267-7.4667 1.2267z" style={{fill:"#fff"}}/>
        <path d="m204.05 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.88q0-0.53334 0.32-0.85334 0.32-0.37333 0.90667-0.37333h2.8267q0.58667 0 0.90667 0.37333 0.32 0.32 0.32 0.85334v34.88q0 0.53333-0.32 0.90666-0.32 0.32-0.90667 0.32z" style={{fill:"#fff"}}/>
        <path d="m230.83 147.67q-4.5867 0-7.84-1.5467-3.2533-1.6-5.0133-4.8533-1.76-3.3067-1.9733-8.2667-0.0533-2.3467-0.0533-4.48 0-2.1867 0.0533-4.5333 0.21334-4.9067 2.0267-8.16 1.8667-3.3067 5.12-4.9067 3.3067-1.6533 7.68-1.6533 4.32 0 7.5733 1.6533 3.3067 1.6 5.1733 4.9067 1.8667 3.2533 2.0267 8.16 0.10666 2.3467 0.10666 4.5333 0 2.1333-0.10666 4.48-0.16 4.96-1.9733 8.2667-1.76 3.2533-5.0133 4.8533-3.2533 1.5467-7.7867 1.5467zm0-4.5333q4.0533 0 6.6133-2.4533 2.6133-2.4533 2.8267-7.9467 0.10666-2.4 0.10666-4.2667 0-1.92-0.10666-4.2667-0.10667-3.68-1.3867-5.9733-1.2267-2.2933-3.3067-3.36-2.08-1.0667-4.7467-1.0667-2.6667 0-4.7467 1.0667-2.08 1.0667-3.36 3.36-1.2267 2.2933-1.3867 5.9733-0.0533 2.3467-0.0533 4.2667 0 1.8667 0.0533 4.2667 0.21334 5.4933 2.8267 7.9467t6.6667 2.4533zm-1.4933-36q-0.85333 0-0.85333-0.85334 0-0.42666 0.26666-0.69333l4-4.8q0.48-0.58666 0.85333-0.8 0.37334-0.21333 1.12-0.21333h3.9467q1.0133 0 1.0133 1.0667 0 0.37333-0.26667 0.64l-6.1867 5.0667q-0.42667 0.37333-0.8 0.48t-0.96 0.10667z" style={{fill:"#fff"}}/>
        <path d="m254.72 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.827q0-0.58667 0.32-0.90667 0.32-0.37333 0.90667-0.37333h2.4533q0.64 0 0.96 0.32 0.32 0.26667 0.37333 0.42667l17.707 27.2v-26.667q0-0.58667 0.32-0.90667 0.32-0.37333 0.90666-0.37333h2.56q0.58667 0 0.90667 0.37333 0.37333 0.32 0.37333 0.90667v34.773q0 0.53333-0.37333 0.90667-0.32 0.37333-0.85333 0.37333h-2.56q-0.58667 0-0.90667-0.32-0.26667-0.32-0.37333-0.42667l-17.653-26.933v26.453q0 0.53333-0.37333 0.90666-0.32 0.32-0.90666 0.32z" style={{fill:"#fff"}}/>
        </g>
        <g id="vision-txt" transform="matrix(.26458 0 0 .26458 96.451 0)"  aria-label="VISIÓN">
        <path d="m127.55 147.13q-0.8 0-1.28-0.37333-0.42666-0.37334-0.69333-1.0133l-11.893-34.293q-0.10667-0.32-0.10667-0.58666 0-0.42667 0.32-0.74667t0.74666-0.32h2.7733q0.64 0 0.96 0.37333 0.37333 0.32 0.48 0.58667l10.4 30.24 10.453-30.24q0.10666-0.26667 0.42666-0.58667 0.32-0.37333 0.96-0.37333h2.7733q0.42667 0 0.74667 0.32 0.37333 0.32 0.37333 0.74667 0 0.26666-0.10666 0.58666l-11.947 34.293q-0.21334 0.63999-0.69334 1.0133-0.42666 0.37333-1.2267 0.37333z" style={{fill:"#fff"}}/>
        <path d="m152.61 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.88q0-0.53334 0.32-0.85334 0.32-0.37333 0.90667-0.37333h2.8267q0.58667 0 0.90667 0.37333 0.32 0.32 0.32 0.85334v34.88q0 0.53333-0.32 0.90666-0.32 0.32-0.90667 0.32z" style={{fill:"#fff"}}/>
        <path d="m177.95 147.67q-4.64 0-7.7867-1.44-3.1467-1.44-4.8533-3.68-1.6533-2.2933-1.76-4.8 0-0.42667 0.32-0.74667 0.37334-0.37333 0.85334-0.37333h2.72q0.63999 0 0.95999 0.32t0.42667 0.74667q0.32 1.28 1.3333 2.56 1.0133 1.2267 2.88 2.08 1.92 0.8 4.9067 0.8 4.5867 0 6.72-1.6 2.1867-1.6533 2.1867-4.3733 0-1.8667-1.1733-2.9867-1.12-1.1733-3.4667-2.08-2.3467-0.90667-6.08-1.9733-3.8933-1.12-6.4533-2.4533-2.56-1.3333-3.84-3.3067-1.2267-1.9733-1.2267-5.0133 0-2.88 1.5467-5.12 1.5467-2.2933 4.48-3.6267t7.04-1.3333q3.3067 0 5.76 0.90666 2.5067 0.85334 4.16 2.3467 1.6533 1.44 2.5067 3.1467 0.85333 1.6533 0.90666 3.2533 0 0.37334-0.26666 0.74667-0.26667 0.32-0.85333 0.32h-2.8267q-0.37333 0-0.8-0.21333-0.37333-0.21334-0.58667-0.8-0.32-2.1867-2.4533-3.68t-5.5467-1.4933q-3.4667 0-5.6533 1.3333-2.1333 1.3333-2.1333 4.16 0 1.8133 1.0133 3.04 1.0133 1.1733 3.2 2.08 2.1867 0.90667 5.7067 1.92 4.2667 1.1733 6.9867 2.5067 2.72 1.28 4 3.2533 1.3333 1.92 1.3333 4.96 0 3.36-1.76 5.76-1.76 2.3467-4.96 3.6267-3.1467 1.2267-7.4667 1.2267z" style={{fill:"#fff"}}/>
        <path d="m200.35 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.88q0-0.53334 0.32-0.85334 0.32-0.37333 0.90667-0.37333h2.8267q0.58667 0 0.90667 0.37333 0.32 0.32 0.32 0.85334v34.88q0 0.53333-0.32 0.90666-0.32 0.32-0.90667 0.32z" style={{fill:"#fff"}}/>
        <path d="m227.12 147.67q-4.5867 0-7.84-1.5467-3.2533-1.6-5.0133-4.8533-1.76-3.3067-1.9733-8.2667-0.0533-2.3467-0.0533-4.48 0-2.1867 0.0533-4.5333 0.21334-4.9067 2.0267-8.16 1.8667-3.3067 5.12-4.9067 3.3067-1.6533 7.68-1.6533 4.32 0 7.5733 1.6533 3.3067 1.6 5.1733 4.9067 1.8667 3.2533 2.0267 8.16 0.10666 2.3467 0.10666 4.5333 0 2.1333-0.10666 4.48-0.16 4.96-1.9733 8.2667-1.76 3.2533-5.0133 4.8533-3.2533 1.5467-7.7867 1.5467zm0-4.5333q4.0533 0 6.6133-2.4533 2.6133-2.4533 2.8267-7.9467 0.10666-2.4 0.10666-4.2667 0-1.92-0.10666-4.2667-0.10667-3.68-1.3867-5.9733-1.2267-2.2933-3.3067-3.36-2.08-1.0667-4.7467-1.0667-2.6667 0-4.7467 1.0667-2.08 1.0667-3.36 3.36-1.2267 2.2933-1.3867 5.9733-0.0533 2.3467-0.0533 4.2667 0 1.8667 0.0533 4.2667 0.21334 5.4933 2.8267 7.9467t6.6667 2.4533zm-1.4933-36q-0.85333 0-0.85333-0.85334 0-0.42666 0.26666-0.69333l4-4.8q0.48-0.58666 0.85333-0.8 0.37334-0.21333 1.12-0.21333h3.9467q1.0133 0 1.0133 1.0667 0 0.37333-0.26667 0.64l-6.1867 5.0667q-0.42667 0.37333-0.8 0.48t-0.96 0.10667z" style={{fill:"#fff"}}/>
        <path d="m251.01 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.827q0-0.58667 0.32-0.90667 0.32-0.37333 0.90667-0.37333h2.4533q0.64 0 0.96 0.32 0.32 0.26667 0.37333 0.42667l17.707 27.2v-26.667q0-0.58667 0.32-0.90667 0.32-0.37333 0.90667-0.37333h2.56q0.58667 0 0.90667 0.37333 0.37333 0.32 0.37333 0.90667v34.773q0 0.53333-0.37333 0.90667-0.32 0.37333-0.85333 0.37333h-2.56q-0.58667 0-0.90667-0.32-0.26666-0.32-0.37333-0.42667l-17.653-26.933v26.453q0 0.53333-0.37334 0.90666-0.32 0.32-0.90666 0.32z" style={{fill:"#fff"}}/>
        </g>
        <g id="valores-txt" transform="matrix(.26458 0 0 .26458 47.784 132.64)" aria-label="VALORES">
        <path d="m95.708 147.13q-0.8 0-1.28-0.37333-0.42667-0.37334-0.69333-1.0133l-11.893-34.293q-0.10667-0.32-0.10667-0.58666 0-0.42667 0.32-0.74667t0.74667-0.32h2.7733q0.64 0 0.96 0.37333 0.37333 0.32 0.48 0.58667l10.4 30.24 10.453-30.24q0.10667-0.26667 0.42667-0.58667 0.32-0.37333 0.96-0.37333h2.7733q0.42666 0 0.74666 0.32 0.37334 0.32 0.37334 0.74667 0 0.26666-0.10667 0.58666l-11.947 34.293q-0.21333 0.63999-0.69333 1.0133-0.42667 0.37333-1.2267 0.37333z" style={{fill:"#fff"}}/>
        <path d="m114.43 147.13q-0.42667 0-0.74667-0.32t-0.32-0.74666q0-0.26667 0.0533-0.58667l13.227-34.507q0.21333-0.53333 0.58666-0.85333 0.37334-0.32 1.12-0.32h3.6267q0.74666 0 1.12 0.32 0.37333 0.32 0.58666 0.85333l13.173 34.507q0.10666 0.32 0.10666 0.58667 0 0.42666-0.32 0.74666t-0.74666 0.32h-2.72q-0.64 0-0.96-0.32-0.26667-0.32-0.37333-0.58666l-2.9333-7.5733h-17.493l-2.9333 7.5733q-0.0533 0.26666-0.37333 0.58666-0.32 0.32-0.96 0.32zm8.5333-13.067h14.4l-7.2-18.933z" style={{fill:"#fff"}}/>
        <path d="m153.89 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.88q0-0.53334 0.32-0.85334 0.32-0.37333 0.90667-0.37333h2.8267q0.53333 0 0.85333 0.37333 0.37334 0.32 0.37334 0.85334v31.467h17.707q0.58667 0 0.90667 0.37334 0.37333 0.32 0.37333 0.90666v2.1333q0 0.53333-0.37333 0.90666-0.32 0.32-0.90667 0.32z" style={{fill:"#fff"}}/>
        <path d="m195.6 147.67q-4.5867 0-7.84-1.5467-3.2533-1.6-5.0133-4.8533-1.76-3.3067-1.9733-8.2667-0.0533-2.3467-0.0533-4.48 0-2.1867 0.0533-4.5333 0.21333-4.9067 2.0267-8.16 1.8667-3.3067 5.12-4.9067 3.3067-1.6533 7.68-1.6533 4.32 0 7.5733 1.6533 3.3067 1.6 5.1733 4.9067 1.8667 3.2533 2.0267 8.16 0.10667 2.3467 0.10667 4.5333 0 2.1333-0.10667 4.48-0.16 4.96-1.9733 8.2667-1.76 3.2533-5.0133 4.8533-3.2533 1.5467-7.7867 1.5467zm0-4.5333q4.0533 0 6.6133-2.4533 2.6133-2.4533 2.8267-7.9467 0.10667-2.4 0.10667-4.2667 0-1.92-0.10667-4.2667-0.10666-3.68-1.3867-5.9733-1.2267-2.2933-3.3067-3.36-2.08-1.0667-4.7467-1.0667-2.6667 0-4.7467 1.0667-2.08 1.0667-3.36 3.36-1.2267 2.2933-1.3867 5.9733-0.0533 2.3467-0.0533 4.2667 0 1.8667 0.0533 4.2667 0.21333 5.4933 2.8267 7.9467 2.6133 2.4533 6.6667 2.4533z" style={{fill:"#fff"}}/>
        <path d="m219.49 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.827q0-0.58667 0.32-0.90667 0.32-0.37333 0.90667-0.37333h13.013q6.0267 0 9.5467 2.8267 3.52 2.8267 3.52 8.3733 0 4.1067-2.08 6.72-2.0267 2.56-5.5467 3.5733l8.2133 14.187q0.16 0.32 0.16 0.58667 0 0.42666-0.37334 0.74666-0.32 0.32-0.74666 0.32h-2.56q-0.90667 0-1.3333-0.48-0.42667-0.48-0.74667-0.96l-7.7333-13.547h-9.3333v13.76q0 0.53333-0.37333 0.90666-0.32 0.32-0.90667 0.32zm4-19.52h8.8q4 0 5.9733-1.6533 1.9733-1.7067 1.9733-5.0133 0-3.2533-1.9733-4.96-1.92-1.7067-5.9733-1.7067h-8.8z" style={{fill:"#fff"}}/>
        <path d="m254.43 147.13q-0.58667 0-0.90667-0.32-0.32-0.37333-0.32-0.90666v-34.827q0-0.58667 0.32-0.90667 0.32-0.37333 0.90667-0.37333h22.027q0.58667 0 0.90667 0.37333 0.32 0.32 0.32 0.90667v2.0267q0 0.58667-0.32 0.90667t-0.90667 0.32h-18.133v11.68h16.96q0.58667 0 0.90666 0.37334 0.32 0.32 0.32 0.90666v1.9733q0 0.53333-0.32 0.90666-0.31999 0.32-0.90666 0.32h-16.96v12.107h18.56q0.58666 0 0.90666 0.32t0.32 0.90667v2.08q0 0.53333-0.32 0.90666-0.32 0.32-0.90666 0.32z" style={{fill:"#fff"}}/>
        <path d="m297.52 147.67q-4.64 0-7.7867-1.44-3.1467-1.44-4.8533-3.68-1.6533-2.2933-1.76-4.8 0-0.42667 0.32-0.74667 0.37334-0.37333 0.85334-0.37333h2.72q0.64 0 0.95999 0.32 0.32 0.32 0.42667 0.74667 0.32 1.28 1.3333 2.56 1.0133 1.2267 2.88 2.08 1.92 0.8 4.9067 0.8 4.5867 0 6.72-1.6 2.1867-1.6533 2.1867-4.3733 0-1.8667-1.1733-2.9867-1.12-1.1733-3.4667-2.08t-6.08-1.9733q-3.8933-1.12-6.4533-2.4533-2.56-1.3333-3.84-3.3067-1.2267-1.9733-1.2267-5.0133 0-2.88 1.5467-5.12 1.5467-2.2933 4.48-3.6267 2.9333-1.3333 7.04-1.3333 3.3067 0 5.76 0.90666 2.5067 0.85334 4.16 2.3467 1.6533 1.44 2.5067 3.1467 0.85333 1.6533 0.90666 3.2533 0 0.37334-0.26666 0.74667-0.26667 0.32-0.85333 0.32h-2.8267q-0.37333 0-0.8-0.21333-0.37333-0.21334-0.58667-0.8-0.32-2.1867-2.4533-3.68t-5.5467-1.4933q-3.4667 0-5.6533 1.3333-2.1333 1.3333-2.1333 4.16 0 1.8133 1.0133 3.04 1.0133 1.1733 3.2 2.08 2.1867 0.90667 5.7067 1.92 4.2667 1.1733 6.9867 2.5067 2.72 1.28 4 3.2533 1.3333 1.92 1.3333 4.96 0 3.36-1.76 5.76-1.76 2.3467-4.96 3.6267-3.1467 1.2267-7.4667 1.2267z" style={{fill:"#fff"}}/>
        </g>
    </g>
</svg>

export let gradient = <defs>
  <linearGradient id="def-gradient-svg" gradientTransform="rotate(45)">
    <stop offset="20%" stop-color="rgba(240,128,0,1)" />
    <stop offset="40%" stop-color="rgba(241,84,80,1)" />
    <stop offset="60%" stop-color="rgba(240,128,0,1)" />
    <stop offset="80%" stop-color="rgba(241,84,80,1)" />
  </linearGradient>
</defs>

export let telephoneicon = <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#000000">
  <path d="M17.46,5c-0.06,0.89-0.21,1.76-0.45,2.59l1.2,1.2c0.41-1.2,0.67-2.47,0.76-3.79H17.46z M7.6,17.02 c-0.85,0.24-1.72,0.39-2.6,0.45v1.49c1.32-0.09,2.59-0.35,3.8-0.75L7.6,17.02z M16.5,3H20c0.55,0,1,0.45,1,1c0,9.39-7.61,17-17,17 c-0.55,0-1-0.45-1-1v-3.49c0-0.55,0.45-1,1-1c1.24,0,2.45-0.2,3.57-0.57c0.1-0.04,0.21-0.05,0.31-0.05c0.26,0,0.51,0.1,0.71,0.29 l2.2,2.2c2.83-1.45,5.15-3.76,6.59-6.59l-2.2-2.2c-0.28-0.28-0.36-0.67-0.25-1.02C15.3,6.45,15.5,5.25,15.5,4 C15.5,3.45,15.95,3,16.5,3z" />
  {gradient}
</svg>

export let mediosicon = <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#000000">
    <path d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"/>
  {gradient}
</svg>

export let mailicon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
  <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/>
  {gradient}
</svg>

export let redirecticon = <svg viewBox="0 0 512 512">
    <g>
		<path d="M352,288.994v127.008H64v-288h96v-64H32c-17.664,0-32,14.336-32,32v352c0,17.696,14.336,32,32,32h352
			c17.696,0,32-14.304,32-32V288.994H352z"/>
	</g>
    <g>
		<path d="M505.6,131.202l-128-96c-4.8-3.648-11.328-4.224-16.736-1.504c-5.44,2.72-8.864,8.256-8.864,14.304v48h-48
			c-97.056,0-176,78.944-176,176c0,7.424,5.12,13.888,12.32,15.584c1.216,0.288,2.464,0.416,3.68,0.416
			c5.952,0,11.552-3.328,14.304-8.832l3.776-7.52c24.544-49.12,73.888-79.648,128.8-79.648H352v48
			c0,6.048,3.424,11.584,8.832,14.304c5.408,2.72,11.936,2.144,16.768-1.504l128-96c4.032-3.008,6.4-7.776,6.4-12.8
			S509.632,134.21,505.6,131.202z"/>
	</g>
</svg>

export let copyicon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
</svg>

export let languageicon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M24 44Q19.8 44 16.15 42.425Q12.5 40.85 9.8 38.15Q7.1 35.45 5.55 31.775Q4 28.1 4 23.9Q4 19.7 5.55 16.075Q7.1 12.45 9.8 9.75Q12.5 7.05 16.15 5.525Q19.8 4 24 4Q28.2 4 31.85 5.525Q35.5 7.05 38.2 9.75Q40.9 12.45 42.45 16.075Q44 19.7 44 23.9Q44 28.1 42.45 31.775Q40.9 35.45 38.2 38.15Q35.5 40.85 31.85 42.425Q28.2 44 24 44ZM31.9 16.5H39.4Q37.75 13.05 34.875 10.75Q32 8.45 28.25 7.5Q29.5 9.35 30.375 11.5Q31.25 13.65 31.9 16.5ZM19.2 16.5H28.9Q28.35 13.85 27.05 11.375Q25.75 8.9 24 7Q22.4 8.35 21.3 10.55Q20.2 12.75 19.2 16.5ZM7.6 28.45H15.55Q15.4 27.1 15.375 26.025Q15.35 24.95 15.35 23.9Q15.35 22.65 15.4 21.675Q15.45 20.7 15.6 19.5H7.6Q7.25 20.7 7.125 21.65Q7 22.6 7 23.9Q7 25.2 7.125 26.225Q7.25 27.25 7.6 28.45ZM19.75 40.5Q18.5 38.6 17.6 36.4Q16.7 34.2 16.1 31.45H8.6Q10.5 35 13 37.025Q15.5 39.05 19.75 40.5ZM8.6 16.5H16.15Q16.7 13.8 17.55 11.675Q18.4 9.55 19.7 7.55Q15.95 8.5 13.15 10.75Q10.35 13 8.6 16.5ZM24 41.1Q25.75 39.3 26.925 36.975Q28.1 34.65 28.85 31.45H19.2Q19.9 34.45 21.075 36.85Q22.25 39.25 24 41.1ZM18.65 28.45H29.4Q29.6 26.9 29.65 25.925Q29.7 24.95 29.7 23.9Q29.7 22.9 29.65 21.975Q29.6 21.05 29.4 19.5H18.65Q18.45 21.05 18.4 21.975Q18.35 22.9 18.35 23.9Q18.35 24.95 18.4 25.925Q18.45 26.9 18.65 28.45ZM28.3 40.45Q31.9 39.3 34.775 37Q37.65 34.7 39.4 31.45H31.95Q31.3 34.15 30.425 36.35Q29.55 38.55 28.3 40.45ZM32.4 28.45H40.4Q40.75 27.25 40.875 26.225Q41 25.2 41 23.9Q41 22.6 40.875 21.65Q40.75 20.7 40.4 19.5H32.45Q32.6 21.25 32.65 22.175Q32.7 23.1 32.7 23.9Q32.7 25 32.625 25.975Q32.55 26.95 32.4 28.45Z"/></svg>;