export const TRANSLATION_FR = {
    //COMPONENTS
    header: {
        name: "DATA Solutions Logicielles",
        name_data: "DATA",
        name_sols: "Solutions Logicielles",
        home: "Home",
        services: "Services",
        contacts: "Contacts",
        us: "Nous"
    },
    contact_form_work: {
        title: "TRAVAILLONS ENSEMBLE !",
        subtitle: "L'EFFICACITÉ ET LA QUALITÉ DANS CHAQUE PROJET",
        notifications: {
            on_submit: "Merci de nous avoir contactés :) !",
            on_submit_error: {
                invalid_email: "Saisissez une adresse électronique valide"
            }
        },
        inputs: {
            input_1: {
                placeholder: "NOM"
            },
            input_2: {
                placeholder: "POSITION"
            },
            input_3: {
                placeholder: "ORGANISATION"
            },
            input_4: {
                placeholder: "MAIL"
            },
            input_5: {
                placeholder: "TELEPHONE"
            },
            input_6: {
                placeholder: "SUJET"
            },
            input_7: {
                placeholder: "PLUS DE DÉTAILS"
            },
        },
        btn_submit: {
            text: "ENVOYER"
        }
    },
    footer: {
        left_section: {
            title: "DATA",
            subtitle: "Solutions Logicielles"
        },
        support_section: {
            title: "Soutien",
            link_1: "Nous contacter",
            link_2: "Nous"
        },
        data_section: {
            title: "DATA",
            link_1: "À propos de DATA",
            link_2: "Services",
            link_3: "Cours",
            link_4: "Privacy policy",
            link_5: "Privacy policy PioPio",
        },
        contact_section: {
            title: "Contacts",
            email: "soporte@serviciosdata.com",
            cellphone: "+591 77718112"
        },
        rights: {
            text: "2022 DATA - Tous droits réservés"
        }
    },

    //VARIABLES

    btn_contact_us: "Nous contacter",

    services: {
        card_1: {
            title : "Custom Software",
            category : "Hautement spécialisé",
            description : "Développement de logiciels en fonction de vos besoins et de vos exigences",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Un logiciel hautement personnalisé, entièrement axé sur la satisfaction de vos besoins",
                _2 : "Bénéficiez des dernières technologies grâce à nos services",
                _3 : "Assistance 24 heures sur 24"
            }
        },
        card_2 : {
            title : "Websites",
            category : "Efficace et évolutif",
            description : "Nous créons votre propre site web et vous donnons une présence virtuelle",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Pages Web adaptatives",
                _2 : "Vous aurez un domaine exclusif",
                _3 : "Des serveurs efficaces et rapides",
                _4 : "Nous avons plusieurs plans qui s'adaptent à vos besoins",
                _5 : "Nous lançons votre marque dans le monde",
                _6 : "Efficace, rapide et personnalisé"
            }
        },
        card_3 : {
            title : "Applications mobiles multiplateformes",
            category : "Intuitif et sécurisé",
            description : "Applications mobiles sur mesure",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Applications mobiles hybrides multiplateformes",
                _2 : "Exécutables pour IOS et Android",
                _3 : "Projets efficaces en termes de temps et de budget",
                _4 : "Peut également être téléchargé sur Play Store(Android) et App Store(IOS)",
                _5 : "Robuste, fiable et efficace"
            }
        },
        card_4 : {
            title : "Design",
            category : "Attrayant",
            description : "Nous concevons votre manuel d'identité",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Nous concevons votre logo avec ses différentes versions et avec le style qui représente le mieux votre marque",
                _2 : "Nous créons votre manuel de marque",
                _3 : "Nous nous basons sur les valeurs de votre entreprise",
                _4 : "Nous créons du contenu pour vos réseaux"
            }
        },
        card_5 : {
            title : "Marketing avec Facebook ADS",
            category : "Excellent positionnement",
            description : "Développement de logiciels en fonction de vos besoins et de vos exigences",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Plus de clients potentiels montrant vos produits sur Facebook",
                _2 : "Nous faisons la promotion de votre page ou de vos services sur Facebook",
                _3 : "Stratégies de marketing efficaces",
                _4 : "Rapprochez-vous de vos adeptes ou de vos clients potentiels"
            }
        },
        card_6 : {
            title : "Integral Consultancy and Training",
            category : "Interactive",
            description : "Nous vous conseillons sur les logiciels",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Nous vous conseillons sur le logiciel dont vous avez besoin en fonction de vos exigences et de votre budget",
                _2 : "Nous vous apprenons à utiliser les logiciels dont vous disposez et à rendre votre travail plus efficace",
                _3 : "Nous offrons des formations aux employés de votre organisation",
            }
        },
        card_7 : {
            title : "Support technique et maintenance",
            category : "Disponible 24/7",
            description : "Nous offrons un support technique et une maintenance pour les logiciels que vous possédez",
            on_hover : "CONTACT US",
            skills : {
                _1 : "Nous ajoutons les fonctionnalités qui vous intéressent au logiciel que vous possédez",
                _2 : "Pour que vous et votre logiciel soyez à l'abri des imprévus, nous pouvons vous offrir une assistance technique 24 heures sur 24, 7 jours sur 7",
                _3 : "Mettez à jour vos logiciels avec les dernières technologies et les derniers correctifs de sécurité",
                _4 : "Nous assurons l'intégrité de vos données"
            }
        },
    },
    benefits: {
        _1 : {
            title : "Custom Software",
            advantages : {
                _1 : "Vous pouvez automatiser les processus répétitifs et augmenter votre productivité"
            }
        },
        _2 : {
            title : "Website",
            advantages : {
                _1 : "Présentez vos produits ou services au monde entier et prenez de l'avance sur vos concurrents"
            }
        },
        _3 : {
            title : "Applications mobiles",
            advantages : {
                _1 : "Démontrez votre professionnalisme en présentant une application mobile à vos consommateurs."
            }
        },
        _4 : {
            title : "Design",
            advantages : {
                _1 : "Attirer plus de clients ou d'adeptes grâce à des designs affirmés et attrayants"
            }
        },
        _5 : {
            title : "Marketing avec Facebook ADS",
            advantages : {
                _1 : "Obtenez plus de prospects en présentant vos produits à un public plus large"
            }
        },
        _6 : {
            title : "Comprehensive consultancy and training",
            advantages : {
                _1 : "Nous vous proposons des formations et des conseils techniques de qualité", 
            }
        },
        _7 : {
            title : "Support technique et maintenance",
            advantages : {
                _1 : "Nous sommes disponibles 24 heures sur 24 pour vous fournir des solutions rapides et adéquates"
            }
        },
    },
    disponible_languages: {
        es: {
            name: "Espagnol"
        },
        en: {
            name: "Anglais"
        },
        fr: {
            name: "Français"
        }
    },

    //PAGES

    home: {
        page_name: "DATA Software Solutions | Entreprise de logiciels",
        sup: {
            title: "DATA Solutions Logicielles",
            description: "Nous nous chargeons de donner à votre entreprise une nouvelle image de marque grâce aux dernières technologies et aux normes de qualité les plus élevées. Notre logiciel est très fiable, robuste et évolutif",
            btn_contact: "Nous contacter",
            btn_budget: "Demander un devis"
        },

        benefits: {
            cards : {
                card_1 : {
                    title : "Custom Software",
                    description : "Vous pouvez automatiser les processus répétitifs et augmenter votre productivité"
                },
                card_2 : {
                    title : "Website",
                    description : "Présentez vos produits ou services au monde entier et prenez une longueur d'avance sur vos concurrents"
                },
                card_3 : {
                    title : "Applications mobiles",
                    description : "Démontrez votre professionnalisme en présentant une application mobile à vos clients"
                },
                card_4 : {
                    title : "Design",
                    description : "Attirer plus de clients ou d'adeptes grâce à des designs affirmés et attrayants"
                },
                card_5 : {
                    title : "Marketing avec Facebook ADS",
                    description : "Obtenez plus de prospects en présentant vos produits à un public plus large"
                },
                card_6 : {
                    title : "Integral Consultancy and Training",
                    description : "Nous vous offrons des formations et des conseils techniques de qualité"
                },
                card_7 : {
                    title : "Support technique et maintenance",
                    description : "Nous sommes disponibles 24 heures sur 24 pour vous offrir des solutions rapides et adéquates"
                },
            },
            section : {
                title : "Avantages de travailler avec nous",
                btn_contact : "Contactez-nous"
            }
        },
        collaborations: {
            title: "NOUS AVONS TRAVAILLÉ AVEC"
        }
    },
    service: {
        page_name: "DATA Software Solutions | Services",
        title: "SERVICES"
    },
    contacts: {
        page_name: "DATA Software Solutions | Contactez-nous",
        title: "NOTRE ÉQUIPE EST LÀ POUR VOUS SOUTENIR",
        subtitle: "Contactez-nous à",
        cellphone_section: {
            title: "Mobile",
            numbers: {
                _1: "+591 77718112",
                _2: "+591 67188973"
            },
        },
        social_network_section: {
            title: "Nos réseaux",
        },
        email_section: {
            title: "Courriel",
            emails: {
                _1: "soporte@serviciosdata.com",
                _2: "christiancopa@serviciosdata.com"
            }
        },
        subtext: "Chez DATA Solutions Logicielles, nous sommes très heureux d'être l'une des premières startups dédiées au développement de logiciels et à la conception de marques en Bolivie."
    },
    about_us: {
        page_name: "DATA Software Solutions | À propos de nous",
        sup: {
            title: "DATA Solutions Logicielles",
            description: "Nous sommes une équipe de développeurs et de concepteurs, concentrés sur la qualité de nos produits et nous proposons de vous fournir une image d'entreprise renouvelée dans le but d'ajouter de la valeur à votre marque avec le plus haut niveau de qualité humaine et de productivité",
        },
        swiper: {
            title: "NOS VALEURS EN TANT QU'ORGANISATION",
            swipes: {
                _1 : {
                    title : "MISSION",
                    description : "DATA cherche à maximiser le potentiel de ses clients par l'utilisation de l'Internet en fournissant des solutions technologiques efficaces qui visent également à augmenter leur productivité"
                },
                _2 : {
                    title : "VISION",
                    description : "Être l'une des meilleures entreprises de développement Web, reconnue pour l'innovation, la simplicité et la génération de valeur de ses solutions, avec une productivité élevée et la qualité humaine de son équipe"
                },
                _3 : {
                    title : "NOS VALEURS",
                    description: "",
                    skills: {
                        _1 : {
                            title : "Honnêteté",
                            description : "Nos produits et services répondent toujours aux normes de qualité et aux spécifications les plus élevées."
                        },
                        _2 : {
                            title : "Responsabilité",
                            description : "Nous prenons la responsabilité de toujours respecter les normes les plus élevées du secteur et de toujours respecter les délais"
                        },
                        _3 : {
                            title : "Respect",
                            description : "Nous nous efforçons de comprendre toutes les personnes avec empathie afin de les traiter avec dignité et respect"
                        },
                        _4 : {
                            title : "Commitment",
                            description : "Nous nous engageons toujours à répondre aux attentes de nos clients, des étudiants et de la population en général"
                        }
                    }
                }
            }
        }
    },
    budget : {
        page_name: "DATA Software Solutions | Nous établissons un devis pour votre logiciel, votre application mobile, votre site web ou tout autre projet.",
        title : "Demandez votre budget",
        description : "Si vous souhaitez apporter des modifications à votre site web actuel ou avoir votre propre site, nous pouvons vous aider à préparer votre budget, pour cela vous pouvez remplir le formulaire ci-dessous en nous donnant quelques détails sur votre projet, cela ne prendra que quelques minutes",
        entries : {
            _1 : {
                placeholder : "Nom"
            },
            _2 : {
                placeholder : "Email"
            },
            _3 : {
                placeholder : "Company"
            },
            _4 : {
                placeholder : "Téléphone ou portable"
            },
            _5 : {
                placeholder : "Country",
            },
            _6 : {
                placeholder : "City",
            },
            _7 : {
                placeholder : "Quel type de produits ou de services proposez-vous ?"
            },
            _8 : {
                placeholder : "De quel type de projet s'agit-il ?",
                options : {
                    _1 : "Site web",
                    _2 : "Application mobile",
                    _3 : "Logiciel de bureau",
                    other : "Autre"
                }
            },
            _9 : {
                placeholder : "De quel service avez-vous besoin ?",
                options:{
                    _1 : "Développement",
                    _2 : "Maintenance",
                    other : "Autre"
                }
            },
            _10 : {
                placeholder : "Expliquez-nous en détail le projet dont vous avez besoin."
            },
            _11 : {
                placeholder : "Y a-t-il une date limite ?",
                options : {
                    no : "Non",
                    yes : "Oui",
                    yes_text : "Délai en jours"
                }
            },
            _12 : {
                placeholder : "Avez-vous un budget déjà défini ?",
                options : {
                    no : "Non",
                    yes : "Oui",
                    yes_text : "Entrez votre budget en dollars."
                }
            },
            _13 : {
                placeholder : "Comment préférez-vous être contacté ?",
                options : {
                    _1 : "WhatsApp",
                    _2 : "Email",
                    _3 : "Téléphone",
                    other : "Autre"
                }
            },
            _14 : {
                placeholder : "Comment avez-vous découvert les Solutions logicielles DATA ?",
                options : {
                    _1 : "Google",
                    _2 : "Facebook",
                    _3 : "Recommandation d'un tiers",
                    other : "Autre"
                }
            }
        },
        policy : {
            text_1 : "J'accepte les ",
            text_2 : " de DATA Software Solutions",
            link : "politique de confidentialité"
        },
        correct:{
            title : "Nous traitons votre devis",
            subtitle : "Nous avons reçu vos informations et un agent va établir un devis pour votre projet, cela peut prendre un certain temps donc en attendant vous pouvez consulter notre page Facebook",
            or : "ou",
            return_home : "Retour en haut"
        },
        btn_send_budget : {
            text : "Demandez votre budget"
        },
        erreurs : {
            invalid_email : "Veuillez saisir une adresse électronique valide",
            error_base : "Une erreur s'est produite lors de la génération de votre devis : ",
            try_again : "Une erreur s'est produite lors de la création de votre devis, veuillez réessayer."
        },
        other_text : "Autre"
    },
    privacy_policy : {
        page_name: "DATA Software Solutions | Politique de confidentialité",
        title : "POLITIQUE DE CONFIDENTIALITÉ",
        description : "Cette politique de confidentialité énonce les conditions dans lesquelles DATA Software Solutions utilise et protège les informations que vous fournissez à DATA Software Solutions lorsque vous utilisez ce site web. Cette société s'engage à assurer la sécurité des données de ses utilisateurs. Lorsque nous vous demandons de remplir des informations personnelles permettant de vous identifier, nous le faisons avec l'assurance qu'elles ne seront utilisées que conformément aux termes du présent document. Toutefois, cette politique de confidentialité peut changer au fil du temps ou être mise à jour et nous vous encourageons à consulter régulièrement cette page afin de vous assurer que vous êtes satisfait de tout changement.        ",
        paragraphs : {
            _1:{
                subtitle : "Les informations qui sont collectées",
                text : "Notre site web peut collecter des informations personnelles telles que : le nom, les informations de contact telles que votre adresse e-mail et des informations démographiques, etc. En outre, lorsque cela est nécessaire, des informations spécifiques peuvent être requises pour traiter une commande ou effectuer une livraison ou une facturation.                "
            },
            _2:{
                subtitle : "Utilisation des informations recueillies",
                text : "Notre site web utilise les informations afin de fournir le meilleur service possible, en particulier pour suivre les utilisateurs, pour suivre les commandes le cas échéant, et pour améliorer nos produits et services. Nous pouvons périodiquement envoyer des courriels par le biais de notre site avec des offres spéciales, de nouveaux produits et d'autres informations commerciales que nous pensons être pertinentes pour vous ou qui pourraient vous être utiles. Ces courriels seront envoyés à l'adresse que vous avez fournie et vous pouvez vous désabonner à tout moment. DATA Software Solutions s'engage à respecter son engagement à assurer la sécurité de vos informations. Nous utilisons des systèmes de pointe et les mettons constamment à jour afin de garantir qu'il n'y ait pas d'accès non autorisé à vos informations personnelles",
            },
            _3:{
                subtitle : "Cookies",
                text : "Un cookie fait référence à un fichier qui est envoyé dans le but de demander la permission d'être stocké sur votre ordinateur, en acceptant ce fichier est créé et le cookie sert alors à avoir des informations sur le trafic Web, et aussi de faciliter et de donner le confort à de futures visites à un site Web récurrent. Une autre fonction des cookies est qu'ils permettent au web de vous reconnaître individuellement et donc de vous fournir un service meilleur et plus personnalisé. Notre site web utilise des cookies pour identifier les pages visitées et leur fréquence. Ces informations ne sont utilisées qu'à des fins d'analyse statistique, puis elles sont définitivement supprimées. Vous pouvez supprimer les cookies à tout moment sur votre ordinateur. Cependant, les cookies aident à fournir un meilleur service à partir des sites web, ils ne donnent pas accès à des informations sur votre ordinateur ou sur vous-même, sauf si vous choisissez de les fournir directement. Vous pouvez accepter ou refuser l'utilisation de cookies, mais la plupart des navigateurs acceptent automatiquement les cookies pour fournir un meilleur service web. Vous pouvez également modifier les paramètres de votre ordinateur pour refuser les cookies.                "
            },
            _4:{
                subtitle : "Liens avec des tiers",
                text : "Ce site web peut contenir des liens vers d'autres sites susceptibles de vous intéresser. Une fois que vous avez cliqué sur ces liens et quitté notre site, nous n'avons plus le contrôle du site vers lequel vous êtes redirigé et nous ne sommes donc pas responsables des conditions ou de la protection de la vie privée ou des données de ces autres sites tiers. Ces sites sont soumis à leurs propres politiques de confidentialité et nous vous recommandons de vérifier auprès d'eux si elles vous conviennent.                ",
            },
            _5:{
                subtitle : "Contrôle de vos informations personnelles",
                text : "Vous pouvez à tout moment restreindre la collecte ou l'utilisation des informations personnelles que vous fournissez à notre site web. Lorsqu'il vous est demandé de remplir un formulaire, par exemple un formulaire d'inscription, vous pouvez cocher ou décocher l'option permettant de recevoir des informations par courrier électronique. Si vous avez coché l'option de recevoir notre bulletin d'information ou notre publicité, vous pouvez l'annuler à tout moment. \Cette entreprise ne vendra pas, ne donnera pas et ne distribuera pas les informations personnelles recueillies sans votre consentement, à moins qu'un juge ne l'exige par une ordonnance du tribunal. DATA Software Solutions se réserve le droit de modifier les termes de cette politique de confidentialité à tout moment"
            }
        }
    }
}