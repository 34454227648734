import React, { useLayoutEffect, useState } from "react";

import "./Nosotros.css";

import { seccionHeight } from "../theme/variables";
import { OtrasEmpresas, SwiperDatos } from "../components/Comps";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

const Nosotros = () => {
    const [sectionHeight, setSectionHeight] = useState(0);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        setSectionHeight(seccionHeight());
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);


    let CardNosotros = (props: { titulo: any, content: any }) => {
        return <div className="card-mision-vision-nosotros">
            <div className="datos-slides">
                <span className="titulo-slide text-bg-gradient">{props.titulo}</span>
                <span className="skill-slide">{props.content}</span>
            </div>
        </div>
    }

    return <div>

        <Helmet>
            <title>{t('about_us.page_name')}</title>
            <meta name="description" content="Nuestra misión, visión y valores como organización | DATA Soluciones en Software" />
            <link rel="canonical" href="https://serviciosdata.com/nosotros" />
        </Helmet>

        <div className="inicio-container-sup-component seccion-color-1 seccion nosotros-landing" style={{ minHeight: (sectionHeight * 0.8) + "px" }}>
            <h1 className="inicio-title-component">{t('about_us.sup.title')}</h1>
            <h3 className="inicio-subtitulo-component">{t('about_us.sup.description')}</h3>

            <button className="inicio-contactanos-component" onClick={() => {
                navigate("/contactos");
            }}>{t('btn_contact_us')}</button>
        </div>

        <div className="seccion-color-2">
            <div className="datos-nosotros">
                <span className="subtitulo-nosotros text-bg-gradient">{t('about_us.swiper.title')}</span>
            </div>

            <div className="mision-vision-nosotros">

                <SwiperDatos className="slides-nosotros" datos={[
                    {
                        titulo: t('about_us.swiper.swipes._1.title'), id: "mision-slide-1", img: "https://firebasestorage.googleapis.com/v0/b/data-soluciones.appspot.com/o/Media%2Fmision.jpg?alt=media&token=5fbbd097-39d3-4a23-bdf2-b2b28d9cdfc7",
                        elem: <CardNosotros titulo={t('about_us.swiper.swipes._1.title')} content={t('about_us.swiper.swipes._1.description')} />
                    },
                    {
                        titulo: t('about_us.swiper.swipes._2.title'), id: "vision-slide-1", img: "https://firebasestorage.googleapis.com/v0/b/data-soluciones.appspot.com/o/Media%2Fvision.jpg?alt=media&token=9476a29e-ff96-4f6a-92e5-3ddacce29033",
                        elem: <CardNosotros titulo={t('about_us.swiper.swipes._2.title')} content={t('about_us.swiper.swipes._2.description')} />
                    },
                    {
                        titulo: t('about_us.swiper.swipes._3.title'), id: "valores-slide-1", img: "https://firebasestorage.googleapis.com/v0/b/data-soluciones.appspot.com/o/Media%2Fvalores.jpg?alt=media&token=9c43f01f-cd3c-49c6-94fc-a048780cca58",
                        elem: <CardNosotros titulo={t('about_us.swiper.swipes._3.title')} content={
                            <><span>{t('about_us.swiper.swipes._3.skills._1.title')}</span>
                                
                                <br />{t('about_us.swiper.swipes._3.skills._1.description')}<br />
                                
                                <span>{t('about_us.swiper.swipes._3.skills._2.title')}</span>
                                
                                <br />{t('about_us.swiper.swipes._3.skills._2.description')}<br />
                                
                                <span>{t('about_us.swiper.swipes._3.skills._3.title')}</span>
                                
                                <br />{t('about_us.swiper.swipes._3.skills._3.description')} <br />
                                
                                <span>{t('about_us.swiper.swipes._3.skills._4.title')}</span>
                                
                                <br />{t('about_us.swiper.swipes._3.skills._4.description')}</>
                        }

                        />
                    }
                ]} />

            </div>
        </div>
        <OtrasEmpresas />


    </div>
}
export default Nosotros;