import { FirebaseDatabaseNode } from "@react-firebase/database";
import React from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import { Loader, Page404 } from "../components/Comps";
import { chevronDown, dot } from "../components/Elements";
import CursoTarget from "../components/mini_componentes/CursoTarget";
import FormCurso from "../components/mini_componentes/FormCurso";
import { seccionHeight } from "../theme/variables";

import "./Cursos.css";

class Cursos extends React.Component<{}, {cargando: boolean, seccionHeight: number, cursos: any[], accion: string, curso:any, firebaseResponse: any, codigoAccion: string, redirect: string}>{
    //curso: el curso al que realizar accion

    constructor(props: any){
        super(props);

        this.state = {cargando: true, seccionHeight: 0, cursos: [], accion: "", curso: {}, firebaseResponse: {}, codigoAccion: "", redirect:""};
    }
    componentDidMount(){
        this.setState({ seccionHeight: seccionHeight() });

        let sit = window.location.pathname.split("/");
        let path = window.location.pathname;
        if(path !== "/cursos/" && path !== "/cursos"){
            if(sit.length == 3 && sit[2]!=="" && sit[1]!==""){
                this.setState({accion: "ver", codigoAccion: sit[2]});
            }else if(sit.length == 4 && sit[3]!=="" && sit[2]!=="" && sit[1]!==""){
                this.setState({accion: "comprar", codigoAccion: sit[3]});
            }
        }
    }
    estado(curso: any){
        let est = curso.finalizado;

        let comp = <div></div>

        switch(est){
            case 0: comp = <div className="curso-estado-element estado-curso-pendiente">{dot}<span className="">Comienza el {curso.fecha_inicio}</span></div>
                        break;
            case 1: comp = <div className="curso-estado-element estado-curso-encurso">{dot}<span className="">En curso</span></div>
                        break;
            case 2: comp = <div className="curso-estado-element estado-curso-finalizado">{dot}<span className="">Finalizado</span></div>
                        break;
            default: comp = <div className="curso-estado-element estado-curso-finalizado">{dot}<span className="">Finalizado</span></div>
                        break;
        }
        return comp;
    }

    supImg(img: string, titulo: string){
        return <div className="curso-page-supimg">
            <div onClick={()=>{
                this.setState({accion: "", curso:{}})
            }}>
                {chevronDown}
                <span>Atrás</span>
            </div>
            <img src={img} alt={titulo} />
        </div>
    }
    supInf(curso: any){
        return <div className="curso-page-supinf">
            <span className="curso-page-supinf-titulo">{curso.titulo}</span>
            <span className="curso-page-supinf-docente">Un curso de {curso.docente}, {curso.docente_dedicacion}</span>
            {this.estado(curso)}            
        </div>
    }
    supDesc(curso:any){
        return <span className="curso-page-supinf-descripcion">{curso.descripcion}</span>;
    }

    skills(curso:any){
        let skill = [];
        for(let i = 0;i<curso.skills.split(", ").length;i++){
            skill.push(<span key={i+"_skill"} className="curso-page-skills-skill">{curso.skills.split(", ")[i]}<br/></span>);
        }

        let dif = <span></span>;
        switch(curso.dificultad){
            case 0: dif = <span className="curso-page-skills-skill">Nivel de Dificultad Básico</span>
                    break;
            case 1: dif = <span className="curso-page-skills-skill">Nivel de Dificultad Intermedio</span>
                    break;
            case 2: dif = <span className="curso-page-skills-skill">Nivel de Dificultad Avanzado</span>
                    break;
            default: dif = <span className="curso-page-skills-skill">Nivel de Dificultad Básico</span>
                    break;
        }

        return <div className="curso-page-skills">
            <span className="curso-page-skills-desc">Con este curso:</span>
            <br/>
            {skill}
            {dif}
            <span className="curso-page-skills-skill">Completalo en {curso.duracion} clases</span>
            <span className="curso-page-skills-skill">Duración de {curso.tiempo} horas</span>
        </div>
    }

    supComprar(curso: any){
        let gratis = curso.precio===0? true: false;
        return <div className="curso-page-supcomprar">
            <span className="curso-page-comprar-precio">{gratis? "Gratis": curso.precio+" Bs"}</span>
            {gratis && curso.finalizado === 0 ?
                <div className="curso-page-boton-comprar" onClick={() => { this.comprarForm(curso) }}><span>Inscribete</span></div>
                :
                !gratis && curso.finalizado === 0?
                    <>
                        <div className="curso-page-boton-comprar" onClick={() => { this.comprarForm(curso) }}><span>Compralo</span></div>
                        <div className="curso-page-boton-page" onClick={() => { this.setState({redirect: "/inscribete/"+curso.codigo}) }}><span>Ya pagué</span></div>
                    </>
                    :
                    <div className="curso-page-boton-comprar" onClick={() => { this.comprarForm(curso) }}><span>Reservalo</span></div>
            }
        </div>
    }

    comprarForm(curso: any){
        this.marcarForm(curso.titulo);
    }
    restartForm(){
        let inp = document.getElementById("curso_datos-form") as HTMLInputElement;
        inp.value = "";
    }
    normal(){
        return this.state.cursos;
    }

    ver(){
        //Revisa si tenemos el state la info de cursos o curso para usar
        let comp =  <div></div>;
        if(this.state.cargando === false ){
            //Se cargaron datos de firebase, no hay nada cargando
            let datoscurso = {titulo:"", img:""};
            try{
                if(this.state.curso.codigo !== undefined){
                    //Se tiene info, seguro se llego desde un click a un elem: path : /cursos
                    ///Info de curso en this.state.curso
                    datoscurso = this.state.curso;
                }else{
                    //No se tiene info en curso, probablemente se llego desde elace
                    //Cuardando info en this.state.curso
                    
                    this.setState({curso: this.state.firebaseResponse[this.state.codigoAccion]})
    
                    datoscurso = this.state.firebaseResponse[this.state.codigoAccion];
                }
    
                comp = <div className="cursos-page-ver">
                    <Helmet>
                        <title>{datoscurso.titulo} | DATA Soluciones en Software</title>
                        <meta name="description" content={datoscurso.titulo + " | DATA Soluciones en Software"} />
                        <link rel="canonical" href={"https://serviciosdata.com/cursos/"+this.state.curso.codigo} />
                    </Helmet>
                    <div>
                        <div className="cursos-page-supsec">
                            {this.supInf(datoscurso)}
                            {this.supImg(datoscurso.img, datoscurso.titulo)}
                        </div>
                        {this.supDesc(datoscurso)}
    
                        {this.skills(datoscurso)}
                    </div>
                    {this.supComprar(datoscurso)}
                </div>
            }catch(err: any){
                comp = <Page404 />
            }
                        
        }
        return comp;
    }

    comprar(){
        //Revisa si tenemos el state la info de cursos o curso para usar
        let comp = this.ver();
        if(this.state.cargando === false){
            //Se cargaron datos de firebase, no hay nada cargando
            let datoscurso = {titulo:"", img:""};
            
            try{
                if(this.state.curso.codigo !== undefined){
                    //Se tiene info, seguro se llego desde un click a un elem: path : /cursos
                    ///Info de curso en this.state.curso
                    datoscurso = this.state.curso;
                }else{
                    //No se tiene info en curso, probablemente se llego desde elace
                    //Cuardando info en this.state.curso
                    
                    this.setState({curso: this.state.firebaseResponse[this.state.codigoAccion]})
    
                    datoscurso = this.state.firebaseResponse[this.state.codigoAccion];
                }
                this.marcarForm(datoscurso.titulo);
            }catch(err:any){
                comp = <Page404 />;
            }
        }

        return comp;
    }

    marcarForm(curso: string){
        let inp = document.getElementById("curso_datos-form") as HTMLInputElement;
        inp.value = curso;

        window.location.href = "#formulario-inscribir";
    } 

    render(){
        return <div>
            {this.state.redirect!== ""?
                <Navigate to={this.state.redirect}/>
                :""
            }
                <Helmet>
                    <title>DATA Soluciones en Software | Cursos</title>
                    <meta name="description" content="Ofrecemos cursos y capacitaciones certificados y avalados por distintas instituciones, con contenido enfocado a la técnologia o diseño | DATA Soluciones en Software" />
                    <link rel="canonical" href="https://serviciosdata.com/cursos" />
                </Helmet>
             <FirebaseDatabaseNode path="cursos" orderByValue="created_on">
                {(d: { value: { [x: string]: any; } | null; })=>{
                    if(d.value !== null && this.state.cargando){
                        this.setState({cargando: false, firebaseResponse: d.value}, ()=>{
                            let cursos = [];
                            for(let i in d.value){
                                cursos.push(<CursoTarget accion={{comprar: (curso: any)=>{
                                    this.setState({accion:"comprar", curso: curso, redirect: "/cursos/"+d.value![i].codigo}, ()=>{/*window.location.href="/cursos/"+d.value[i].codigo*/});
                                }, ver:(curso: any)=>{
                                    this.setState({accion: "ver", curso: curso, redirect: "/cursos/"+d.value![i].codigo}, ()=>{/*window.location.href="/cursos/"+d.value[i].codigo*/})
                                }}} curso={d.value[i]}/>);
                            }
                            this.setState({cursos: cursos});
                        });
                    }
                    
                    return <></>
                }}
            </FirebaseDatabaseNode>
            <div className="cursos-component-page" style={{minHeight: this.state.seccionHeight+"px", alignItems: (!this.state.cargando && (this.state.accion==="ver" || this.state.accion==="comprar")? "flex-start":"center")}}>
                {this.state.cargando? 
                    <Loader />
                : 
                    this.state.accion ===""?
                        this.normal()
                    :
                        this.state.accion === "ver"?
                            this.ver()
                        :
                            this.state.accion === "comprar"?
                                this.comprar()
                            :
                                this.normal()
                }
            </div>
            <FormCurso />
        </div>
    }
}
export default Cursos;