import { FirebaseDatabaseNode } from "@react-firebase/database";
import React from "react";
import { toast } from "react-toastify";
import { Input, Loader } from "../components/Comps";

import * as EmailValidator from 'email-validator';

import { allComplete, getDate, toastError, toastSuccess } from "../theme/variables";

import "./Inscribete.css";

class Inscribete extends React.Component<{}, {curso: string, cargando:boolean, response: any, firebaseResponse: any, subiendo:boolean, activa: boolean, correcto: boolean, usuario:any}>{
    constructor(props:any){
        super(props);
        this.state = {curso: "", cargando: true, response:{}, firebaseResponse: {}, subiendo: false, activa: false, correcto: false, usuario:{}};
    }

    componentDidMount(){
        let path = window.location.pathname.split("/");
        if(path){
            if(path[path.length-2] === "inscribete" && path[path.length-1] !== undefined){
                this.setState({curso: path[path.length-1]}, ()=>{
                    /*
                    axios."academy/cursos.php", {
                        clave: "obtain-curso",
                        code: parseInt(this.state.curso)
                    }, {}).then(res=>{
                        console.log(res.data)
                    })
                    */
                });
            }
        }
    }

    componentDidUpdate(){
        if(this.state.subiendo){
            toast.info("Subiendo...",{
                position: "top-right",
                autoClose: this.state.subiendo? 10000:0,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else{
            if(this.state.activa){
                
            }
        }
    }

    correoValido() {
        let correo = document.getElementById("correo-form-inscribir") as HTMLInputElement;
        return EmailValidator.validate(correo.value);
    }

    enviar(datos: any){        
        let codigo  = document.getElementById(datos.codigo) as HTMLInputElement;
        let im = document.getElementById(datos.imagen) as HTMLInputElement;
        let mensaje = document.getElementById(datos.mensaje) as HTMLSpanElement;

        let nombre = document.getElementById(datos.nombre) as HTMLInputElement;
        let apellidos = document.getElementById(datos.apellidos) as HTMLInputElement;
        let celular = document.getElementById(datos.celular) as HTMLInputElement;
        let correo = document.getElementById(datos.correo) as HTMLInputElement;
        let pais = document.getElementById(datos.pais) as HTMLInputElement;
        let ciudad  = document.getElementById(datos.ciudad) as HTMLInputElement;

        if (allComplete([codigo, nombre, apellidos, celular, correo, pais, ciudad]) && this.correoValido()) {
            if (im.files!.length === 0) {
                toastError("Sube una fotografia válida")
            } else {
                this.setState({ subiendo: true });

                const url1 = "https://solucionesdata.com/academy/inscribete.php";

                let data = new FormData();
                data.append("file", im!.files![0]);
                data.append("code", codigo.value);
                data.append("nombre", nombre.value);
                data.append("apellidos", apellidos.value);
                data.append("correo", correo.value);
                data.append("curso", this.state.curso);
                data.append("celular", celular.value);
                data.append("pais", pais.value);
                data.append("ciudad", ciudad.value);
                data.append("fecha", getDate());
                data.append("clave", "inscribir");

                var config = {
                    onUploadProgress: function (progressEvent: any) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        mensaje.innerText = "Subiendo " + percentCompleted + "%";
                    }, headers: {
                        'Content-Type': 'multipart/form-data'
                    }

                };
                const axios = require('axios');

                axios.post(url1, data, config)
                    .then((res: any) => {
                        let respuesta = res.data;   
                        console.log(respuesta.replaceAll("\n",''))
                        switch(respuesta.replaceAll("\n",'')){
                            case "success": toastSuccess("Inscripción realizada correctamente, se te agregará a los grupos en un plazo de 1 a 3 días previa la primera clase")
                                            mensaje.innerText = "Subido con exito";
                                            this.setState({correcto:true, usuario:{nombre: nombre.value, apellidos: apellidos.value, celular: celular.value, correo: correo.value, pais:pais.value, ciudad: ciudad.value}})
                                            setTimeout(() => {
                                                mensaje.innerText = "";
                                            }, 5000);
                                            break;
                            
                            case "format_incorrect": toastError("Sube un archivo válido, solamente se permiten archivos de imagen jpg, png, jpeg, gif y pdf's")
                                                        mensaje.innerText = "Archivo invalido";
                                                        setTimeout(() => {
                                                            mensaje.innerText = "";
                                                        }, 5000);
                                                        break;
                            case "error": toastError("Error, porfavor intenta más tarde");
                                        mensaje.innerText = "";
                                        break;
                            case "code_incorrect": toastError("Código desconocido, revisalo o pide otro a uno de nuestros agentes o mediante nuestros medios en la seccion Contactos");
                                                    mensaje.innerText = "Código invalido";
                                                    setTimeout(() => {
                                                        mensaje.innerText = "";
                                                    }, 5000);
                                                    break;
                            default: toastError("Error, porfavor intenta más tarde");
                                    mensaje.innerText = "";
                                    break;
                        }
                    })
                    .catch((err: any) => {
                        console.log("Error");
                        mensaje.innerText = "Error, intenta nuevamente despues";
                        setTimeout(() => {
                            mensaje.innerText = "";
                        }, 5000);
                    });

            }
        }
        else if(!this.correoValido()){
            toastError("Ingresa un correo válido")
        }else{
            toastError("Completa todos los campos primero")
        }
        

    }

    render(){
        return <div className="seccion-color-2 inscribete-page">
            
            <FirebaseDatabaseNode path="cursos" orderByValue="created_on">
                {(d: { value: null; })=>{
                    if(d.value !== null && this.state.cargando){
                        this.setState({firebaseResponse: d.value, cargando: false}, ()=>{
                            console.log(this.state.firebaseResponse);
                        })

                    }
                    
                    return <></>
                }}
            </FirebaseDatabaseNode>

            {this.state.cargando? 
                <Loader />
            : 
                <>
                    

                    <div className="form-curso-area">
                        <span>{"> "}Inscribete</span>
                        <span className="titulo-curso-form-area">{this.state.firebaseResponse[this.state.curso].titulo}</span>

                        <div>

                        </div>
                    </div>
                    <div className="form-body-inscribete">

                        <div className="nota-inscribir-form">
                            <span>Nota: <p>Coloca correctamente todos los datos, ya que se colocarán de esa manera en tu <strong>Certificado</strong></p></span>
                        </div>
                        <div>

                        </div>

                        <Input title="Nombre" className="form-inscribir-input" id="nombre-form-inscribir" color="light"/>
                        <Input title="Apellidos" className="form-inscribir-input" id="apellidos-form-inscribir" color="light"/>
                        <Input title="Celular con WhatsApp" className="form-inscribir-input" id="celular-form-inscribir" color="light"/>
                        <Input title="Correo electrónico" className="form-inscribir-input" id="correo-form-inscribir" color="light"/>
                        <Input title="País" className="form-inscribir-input" id="pais-form-inscribir" color="light"/>
                        <Input title="Ciudad" className="form-inscribir-input" id="ciudad-form-inscribir" color="light"/>

                        <Input title="CÓDIGO" className="form-inscribir-input" id="codigo-form-inscribir" color="light"/>                        
                        
                        <div className="subida-archivo-form-inscribir">
                            <span>Sube tu comprobante de depósito</span> 
                            <span>El archivo puede ser solamente de tipo imagen o pdf</span>
                            <input type="file" className="form-inscribir-input" id="imagen-form-inscribir" onChange={()=>{
                                this.setState({activa: true})
                            }}/>
                            <span id="mensaje-inscribir-input"></span>
                        </div>

                        <button onClick={()=>{
                            this.enviar({nombre: "nombre-form-inscribir", apellidos: "apellidos-form-inscribir", celular: "celular-form-inscribir", correo: "correo-form-inscribir", pais: "pais-form-inscribir", ciudad: "ciudad-form-inscribir", codigo: "codigo-form-inscribir", imagen: "imagen-form-inscribir", mensaje:"mensaje-inscribir-input"})
                        }}>Enviar</button>
                    </div>  
                </>   
            }
        </div>
    }
}
export default Inscribete;
