export const TRANSLATION_ES = {
    //COMPONENTS
    header: {
        name: "DATA Soluciones en Software",
        name_data: "DATA",
        name_sols: "Soluciones en Software",
        home: "Inicio",
        services: "Servicios",
        contacts: "Contactos",
        us: "Nosotros"
    },
    contact_form_work: {
        title: "¡TRABAJEMOS JUNTOS!",
        subtitle: "EFICIENCIA Y CALIDAD EN CADA PROYECTO",
        notifications: {
            on_submit: "¡Gracias por contactarte con nosotros :)!",
            on_submit_error: {
                invalid_email: "Ingresa un correo válido"
            }
        },
        inputs: {
            input_1: {
                placeholder: "NOMBRE"
            },
            input_2: {
                placeholder: "CARGO"
            },
            input_3: {
                placeholder: "ORGANIZACIÓN"
            },
            input_4: {
                placeholder: "CORREO"
            },
            input_5: {
                placeholder: "TELEFONO"
            },
            input_6: {
                placeholder: "ASUNTO"
            },
            input_7: {
                placeholder: "MÁS DETALLES"
            },
        },
        btn_submit: {
            text: "ENVIAR"
        }
    },
    footer: {
        left_section: {
            title: "DATA",
            subtitle: "Soluciones en Software"
        },
        support_section: {
            title: "Soporte",
            link_1: "Contactanos",
            link_2: "Nosotros"
        },
        data_section: {
            title: "DATA",
            link_1: "Acerca de DATA",
            link_2: "Servicios",
            link_3: "Cursos",
            link_4: "Politica de uso y privacidad",
            link_5: "Política de uso y privacidad PioPio",
        },
        contact_section: {
            title: "Contactos",
            email: "soporte@serviciosdata.com",
            cellphone: "+591 77718112"
        },
        rights: {
            text: "2022 DATA - Todos los derechos reservados"
        }
    },

    //VARIABLES

    btn_contact_us: "Contactanos",

    services: {
        card_1: {
            title: "Software a Medida",
            category: "Altamente especializado",
            description: "Desarrollo de Software según tus necesidades y requerimientos",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Software altamente personalizado, enfocado por completo a cumplir con tus necesidades",
                _2: "Obtén lo ultimo en tecnologia con nuestros servicios",
                _3: "Soporte las 24 Horas del día"
            }
        },
        card_2: {
            title: "Páginas Web",
            category: "Eficiente y escalable",
            description: "Creamos tu propio portal web y te damos presencia virtual",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Páginas Web Adaptativas",
                _2: "Tendrás un dominio exclusivo",
                _3: "Servidores eficientes y veloces",
                _4: "Tenemos multiples planes que se adaptan a tus necesidades",
                _5: "Lanzamos tu marca al mundo",
                _6: "Eficientes, veloces y personalizados"
            }
        },
        card_3: {
            title: "Aplicaciones Móviles Multiplataforma",
            category: "Intuitivo y seguro",
            description: "Aplicaciones móviles a tu medida",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Aplicaciones móviles hibridas multiplataforma",
                _2: "Ejecutables para IOS y Android",
                _3: "Proyectos eficaces en tiempo y presupuesto",
                _4: "Podrán tambien ser subidos a Play Store(Android) y App Store(IOS)",
                _5: "Robustos, confiables y Eficientes"
            }
        },
        card_4: {
            title: "Diseño",
            category: "Atractivo",
            description: "Diseñamos tu Manual de Identidad",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Diseñamos tu Logo con sus distintas versiones y con el estilo que mas represente tu marca",
                _2: "Creamos tu Manual de Marca",
                _3: "Nos basamos en los valores de tu empresa",
                _4: "Creamos contenido para tus redes"
            }
        },
        card_5: {
            title: "Marketing con Facebook ADS",
            category: "Excelente posicionamiento",
            description: "Desarrollo de Software según tus necesidades y requerimientos",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Más clientes potenciales mostrando tus productos por Facebook",
                _2: "Promocionamos tu página o servicios en Facebook",
                _3: "Estrategias de Marketing Efectivo",
                _4: "Acercate más a tus seguidores o clientes potenciales"
            }
        },
        card_6: {
            title: "Asesoria Integral y Capacitaciones",
            category: "Interactiva",
            description: "Te asesoramos en Software",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Te aconsejamos en el software que necesitas regún tus requerimientos y tu presupuesto",
                _2: "Te enseñamos a manejar el software que posees y como hacer más eficiente el trabajo",
                _3: "Ofrecemos capacitaciones a los trabajadores de tu organización"
            }
        },
        card_7: {
            title: "Soporte técnico y mantenimiento",
            category: "Disponible 24/7",
            description: "Te ofrecemos Soporte Técnico y mantenimiento al Software que posees",
            on_hover: "CONTACTÁNOS",
            skills: {
                _1: "Agregamos las funcionalidades que te interesen al Software que posees",
                _2: "Mantente seguro a ti y a tu Software ante imprevistos, podemos ofrecerte el soporte técnico las 24 Horas 7 días de la semana",
                _3: "Actualiza tu Software con las últimas tecnologias y parches de Seguridad",
                _4: "Velamos por la integridad de tus datos"
            }
        },
    },
    benefits: {
        _1: {
            title: "Software a Medida",
            advantages: {
                _1: "Puedes automatizar procesos repetitivos e incrementar tu productividad"
            }
        },
        _2: {
            title: "Página Web",
            advantages: {
                _1: "Presenta tus productos o servicios ante todo el mundo y adelantate a tu competencia"
            }
        },
        _3: {
            title: "Aplicaciones Móviles",
            advantages: {
                _1: "Demuestra tu profesionalismo presentando una aplicación móvil a tus consumidores"
            }
        },
        _4: {
            title: "Diseño",
            advantages: {
                _1: "Atrae más clientes o seguidores mediante diseños asertivos y atractivos"
            }
        },
        _5: {
            title: "Marketing con Facebook ADS",
            advantages: {
                _1: "Obtén más clientes potenciales presentando tus productos a un público mayor"
            }
        },
        _6: {
            title: "Asesoria Integral y Capacitaciones",
            advantages: {
                _1: "Te ofrecemos capacitaciones y asesorias en técnologia de alta calidad"
            }
        },
        _7: {
            title: "Soporte técnico y mantenimiento",
            advantages: {
                _1: "Estamos disponibles las 24 horas para ofrecerte soluciones prontas y adecuadas"
            }
        },
    },
    disponible_languages: {
        es: {
            name: "Español"
        },
        en: {
            name: "Ingles"
        },
        fr: {
            name: "Francés"
        }
    },

    //PAGES

    home: {
        page_name: "DATA Soluciones en Software | Empresa de Software",

        sup: {
            title: "Soluciones Técnologicas a tú medida",
            description: "Nos encargamos de darle una nueva imagen corporativa a tu negocio con lo último en técnologia y los más altos estandares de calidad. Nuestro Software es altamente confiable, robusto y escalable",
            btn_contact: "Contactanos",
            btn_budget: "Solicita tu presupuesto",
            
        },

        benefits: {
            cards: {
                card_1: {
                    title: "Software a Medida",
                    description: "Puedes automatizar procesos repetitivos e incrementar tu productividad"
                },
                card_2: {
                    title: "Página Web",
                    description: "Presenta tus productos o servicios ante todo el mundo y adelantate a tu competencia"
                },
                card_3: {
                    title: "Aplicaciones Móviles",
                    description: "Demuestra tu profesionalismo presentando una aplicación móvil a tus consumidores"
                },
                card_4: {
                    title: "Diseño",
                    description: "Atrae más clientes o seguidores mediante diseños asertivos y atractivos"
                },
                card_5: {
                    title: "Marketing con Facebook ADS",
                    description: "Obtén más clientes potenciales presentando tus productos a un público mayor"
                },
                card_6: {
                    title: "Asesoria Integral y Capacitaciones",
                    description: "Te ofrecemos capacitaciones y asesorias en técnologia de alta calidad"
                },
                card_7: {
                    title: "Soporte técnico y mantenimiento",
                    description: "Estamos disponibles las 24 horas para ofrecerte soluciones prontas y adecuadas"
                },
            },
            section: {
                title: "Beneficios de trabajar con nosotros",
                btn_contact: "Contactanos"
            }
        },
        collaborations: {
            title: "HEMOS TRABAJADO CON"
        }
    },
    service: {
        page_name: "DATA Soluciones en Software | Servicios",
        title: "SERVICIOS"
    },
    contacts: {
        page_name: "DATA Soluciones en Software | Contactanos",
        title: "NUESTRO EQUIPO ESTÁ AQUÍ PARA APOYARTE",
        subtitle: "Contáctanos",
        cellphone_section: {
            title: "Celular",
            numbers: {
                _1: "+591 77718112",
                _2: "+591 67188973"
            },
        },
        social_network_section: {
            title: "Nuestras Redes",
        },
        email_section: {
            title: "Correo",
            emails: {
                _1: "soporte@serviciosdata.com",
                _2: "christiancopa@serviciosdata.com"
            }
        },
        subtext: "Desde DATA Soluciones en Software estamos muy contentos de poder ser una de las primeras Startups que se dedican al Desarrollo de Software y Diseño de Marca en Bolivia."
    },
    about_us: {
        page_name: "DATA Soluciones en Software | Nosotros",
        sup: {
            title: "DATA Soluciones en Software",
            description: "Somos un equipo de desarrolladores y diseñadores, enfocados en la calidad de nuestros productos y ofrecemos brindarles una imagen corporativa renovada con vista en agregarle valor a su marca con el nivel más alto en calidad humana y productividad",
        },
        swiper: {
            title: "NUESTROS VALORES COMO ORGANIZACIÓN",
            swipes: {
                _1: {
                    title: "MISIÓN",
                    description: "DATA busca maximizar el potencial de sus clientes a través del uso de Internet dando soluciones tecnológicas eficientes que además buscan incrementar su productividad"
                },
                _2: {
                    title: "VISIÓN",
                    description: "Ser una de las mejores empresas de Desarrollo Web, reconocida por la innovación, simpleza y generación de valor de sus soluciones, con una alta productividad y calidad humana de su equipo"
                },
                _3: {
                    title: "NUESTROS VALORES",
                    description: "",
                    skills: {
                        _1: {
                            title: "Honestidad",
                            description: "Nuestros productos y servicios siempre cumplen con las especificaciones y los más altos estándares de calidad"
                        },
                        _2: {
                            title: "Responsabilidad",
                            description: "Asumimos la responsabilidad de siempre responder a los estándares más altos en la industria y cumpliendo siempre con los plazos establecidos"
                        },
                        _3: {
                            title: "Respeto",
                            description: "Nos esforzamos por comprender de manera empática a todas las personas a fim de tratarlos con dignidad y respeto "
                        },
                        _4: {
                            title: "Compromiso",
                            description: "Asumimos siempre el compromiso de cumplir con las espectativas de nuestros clientes, estudiantes y personas en general"
                        }
                    }
                }
            }
        }
    },
    budget: {
        page_name: "DATA Soluciones en Software | Cotizamos tu proyecto de software, aplicaciñon móvil, pagina web u otro",
        title: "Solicita tu presupuesto",
        description: "Si estas interesado en realizar modificaciones a tu página web actual o tener la tuya propia podemos ayudarte preparando tu presupuesto, para ello puedes llenar el siguiente formulario contandonos algunos detalles de tu proyecto, solamente te llevará unos minutos",
        entries: {
            _1: {
                placeholder: "Nombre"
            },
            _2: {
                placeholder: "Correo electronico"
            },
            _3: {
                placeholder: "Empresa"
            },
            _4: {
                placeholder: "Telefono o celular"
            },
            _5: {
                placeholder: "Pais"
            },
            _6: {
                placeholder: "Ciudad"
            },
            _7: {
                placeholder: "¿Qué tipo de productos o servicios ofreces?"
            },
            _8: {
                placeholder: "¿Qué tipo de proyecto es?",
                options: {
                    _1: "Página web",
                    _2: "Aplicacion móvil",
                    _3: "Software de escritorio",
                    other: "Otro"
                }
            },
            _9: {
                placeholder: "¿Qué servicio necesitas?",
                options:{
                    _1: "Desarrollo",
                    _2: "Mantenimiento",
                    other: "Otro"
                }
            },
            _10: {
                placeholder: "Explicanos en detalle el proyecto que necesitas",
            },
            _11: {
                placeholder: "¿Hay fecha límite de entrega?",
                options: {
                    no: "No",
                    yes: "Si",
                    yes_text: "Tiempo limite en días"
                }
            },
            _12: {
                placeholder: "¿Dispones de un presupuesto ya establecido?",
                options: {
                    no: "No",
                    yes: "Si",
                    yes_text: "Ingresa tu presupuesto en dólares"
                }
            },
            _13: {
                placeholder: "¿Cómo prefieres que te contactemos?",
                options: {
                    _1: "WhatsApp",
                    _2: "Correo Electrónico",
                    _3: "Teléfono",
                    other: "Otro"
                }
            },
            _14: {
                placeholder: "¿Cómo te enteraste de DATA Soluciones en Software?",
                options: {
                    _1: "Google",
                    _2: "Facebook",
                    _3: "Recomendacion de un tercero",
                    other: "Otro"
                }
            }
        },
        policy: {
            text_1: "Acepto la ",
            text_2: " de DATA Soluciones en Software",
            link: "política de privacidad"
        },
        correct:{
            title: "Estamos procesando tu cotización",
            subtitle: "Recibimos tu información y un agente se encargará de cotizar tu proyecto, podría tomar algo de tiempo así que mientras tanto puedes revisar nuestra página en Facebook",
            or: "ó",
            return_home: "Volver al inicio"
        },
        btn_send_budget: {
            text: "Solicita tu presupuesto"
        },
        errors: {
            invalid_email: "Porfavor ingresa un correo válido",
            error_base: "Ha ocurrido un error generando tu cotización: ",
            try_again: "Ha ocurrido un error generando tu cotización, porfavor intentalo nuevamente."
        },
        other_text: "Otro"
    },
    privacy_policy: {
        page_name: "DATA Soluciones en Software | Política de privacidad",
        title: "POLÍTICA DE PRIVACIDAD",
        description: "El presente Política de Privacidad establece los términos en que DATA Soluciones en Software usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.        ",
        paragraphs: {
            _1:{
                subtitle: "Información que es recogida",
                text: "Nuestro sitio web podrá recoger información personal por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información demográfica, etc. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.                "
            },
            _2:{
                subtitle: "Uso de la información recogida",
                text: "Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento. DATA Soluciones en Software está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado a su información personal."
            },
            _3:{
                subtitle: "Cookies",
                text: "Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita y da comodidad a las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte un servicio mejor y más personalizado. Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente . Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies.                "
            },
            _4:{
                subtitle: "Enlaces a Terceros",
                text: "Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre el sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.                "
            },
            _5:{
                subtitle: "Control de su información personal",
                text: "En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento. \nEsta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.\nDATA Soluciones en Software Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento."
            },
        }
    }
}