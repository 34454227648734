import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ContactosMedios } from "../components/Comps";
import FormContact from "../components/mini_componentes/FormContact";

import "./Contactos.css";

import "../translations/i18n";

const Contactos = () => {
    const {t} = useTranslation();

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return <div>
        <Helmet>
            <title>{t('contacts.page_name')}</title>
            <meta name="description" content="Contactate con nosotros a través de nuestras redes o mediante correo electrónico | DATA Soluciones en Software | Atención disponible las 24 horas" />
            <link rel="canonical" href="https://serviciosdata.com/contactos" />
        </Helmet>
        <div className="seccion seccion-sup" >
            <h1 className="title-contactos-sup">{t('contacts.title')}</h1>
            <h2 className="subtitle-contactos-sup">{t('contacts.subtitle')}</h2>

            <ContactosMedios />

            {/*<div className="perfiles-seccion-wrap">
                    <Perfil datos={{nombre: "DATA Soluciones en Software", 
                                        img: "https://firebasestorage.googleapis.com/v0/b/data-soluciones.appspot.com/o/DATA-1-transparent.png?alt=media&token=9f749362-85c1-4633-9a3e-39c9ae9ebed4",
                                        cargo:"EMPRESA", descripcion:"Cuenta y datos organizacionales",
                                        correo:"soporte@serviciosdata.com" ,wpp:"+591 67188973" ,linkedin:"https://serviciosdata.com"}}/>
                    <Perfil datos={{nombre: "Christian Copa Alvarez", 
                                        img: "https://firebasestorage.googleapis.com/v0/b/data-soluciones.appspot.com/o/foto-perfil.jpg?alt=media&token=cab4a746-d408-4f46-a5aa-d24c75302211",
                                        cargo:"COFUNDADOR \nDESARROLLADOR", descripcion:"Encargado del desarrollo de Software en DATA",
                                        correo:"christiancopa@serviciosdata.com" ,wpp:"+591 67188973" ,linkedin:"https://linkedin.com/in/christian-copa-alvarez/"}}/>
                    <Perfil datos={{nombre: "Yamile del Rosario Morales Poma", 
                                        img: "https://firebasestorage.googleapis.com/v0/b/data-soluciones.appspot.com/o/yamile-foto.jpeg?alt=media&token=14f0d9a2-b84b-4b9a-aade-710cd46942a6",
                                        cargo:"COFUNDADORA \nDISEÑADORA", descripcion:"Diseñadora gráfica y docente en DATA",
                                        correo:"ymorales@serviciosdata.com" ,wpp:"+591 75204042" ,linkedin:"https://linkedin.com/in/yamile-del-rosario-morales-poma-68a25a184/"}}/>                       
                </div>  */}

            <h3 className="descripcion-contactos-sup">{t('contacts.subtext')}</h3>
        </div>



        <FormContact />
    </div>

}
export default Contactos;