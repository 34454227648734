
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Inicio from './pages/Inicio';
import Nosotros from './pages/Nosotros';
import Servicios from './pages/Servicios';
import Header from './components/Header';

import "./theme/variables.css";
import "./theme/globals.scss"

import Contactos from './pages/Contactos';
import Footer from './components/Footer';
import Cursos from './pages/Cursos';
import Certificado from './pages/Certificado';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FirebaseDatabaseProvider } from "@react-firebase/database";
import Inscribete from './pages/Inscribete';
import Cotizacion from './pages/Cotizacion';
import PoliticaDePrivacidad, { PoliticaDePrivacidadPioPio } from './pages/PoliticaDePrivacidad';
import SuccessSendedBudget from './pages/SuccessSendedBudget';


const App = () => {
  const __config = {
    apiKey: "AIzaSyCUkzB3535_Lg8GeC1yZDL0cz6vlgcx-L8",
    authDomain: "data-soluciones.firebaseapp.com",
    databaseURL: "https://data-soluciones-default-rtdb.firebaseio.com",
    projectId: "data-soluciones",
    storageBucket: "data-soluciones.appspot.com",
    messagingSenderId: "379060611119",
    appId: "1:379060611119:web:7cd00322e27466a45d7eda",
    measurementId: "G-P7GS5HS5WH"
  };
    return ( <BrowserRouter>
        <Header />
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover/>

        <Routes>
          <Route path="/servicios" element={<Servicios />}/>

          {/*
          <Route path="/productos">
            <Productos />
          </Route>*/}
          <Route path="/cursos" element={<Cursos />} />

          <Route path="/cursos/:ver" element={<Cursos />} />

          <Route path="/inscribete/:codigo" element={<Inscribete />}/>

          <Route path="/cursos/:evento/:ver" element={<Cursos />}/>

          <Route path="/contactos" element={<Contactos />}/>

          <Route path="/nosotros" element={<Nosotros />}/>
          
          <Route path="/cotizacion" element={<Cotizacion />}/>

          <Route path='/success-budget' element={<SuccessSendedBudget />} />

          <Route path="/politica-de-privacidad" element={<PoliticaDePrivacidad />}/>

          <Route path="/politica-de-privacidad/PioPio" element={<PoliticaDePrivacidadPioPio />}/>
          
          <Route path="/certificado/:codigo" element={<Certificado/>}/>

          <Route path="/" element={<Inicio />}/>
          
        </Routes>

        <Footer />
      </BrowserRouter>
    );
  
}

export default App;
