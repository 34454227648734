import { FirebaseDatabaseMutation } from "@react-firebase/database";
import React from "react";
import { addClassName, removeClassName, seccionHeight } from "../../theme/variables";
import { Input, Notificacion } from "../Comps";

import "./FormContact.css";

class FormCurso extends React.Component<{}, { seccionHeight: number, enviarBoton: boolean, formulario: any }>{
    _config = {
        apiKey: "AIzaSyCUkzB3535_Lg8GeC1yZDL0cz6vlgcx-L8",
        authDomain: "data-soluciones.firebaseapp.com",
        databaseURL: "https://data-soluciones-default-rtdb.firebaseio.com",
        projectId: "data-soluciones",
        storageBucket: "data-soluciones.appspot.com",
        messagingSenderId: "379060611119",
        appId: "1:379060611119:web:7cd00322e27466a45d7eda",
        measurementId: "G-P7GS5HS5WH"
    };
    constructor(props: any) {
        super(props);
        this.state = { seccionHeight: 0, enviarBoton: false, formulario: [] };
    }
    componentDidMount() {
        this.setState({ seccionHeight: seccionHeight() });

        let formularioState = () => {
            let nombre = document.getElementById("nombre_datos-form") as HTMLInputElement;
            let correo = document.getElementById("correo_datos-form") as HTMLInputElement;
            let celular = document.getElementById("celular_datos-form") as HTMLInputElement;
            let curso = document.getElementById("curso_datos-form") as HTMLInputElement;

            let inps = [nombre, correo, celular, curso];

            this.setState({ formulario: inps });

            let isCorrect = () => {
                let nums = [inps[0].value.length, inps[1].value.length, inps[2].value.length, inps[3].value.length];
                //Cuantos tienen >0
                let exist = 0;
                for (let i = 0; i < nums.length; i++) {
                    if (nums[i] > 0) {
                        exist++;
                    }
                }
                if (exist === nums.length) {
                    //Si todos los campos tiene al menos >0
                    return true;
                } else {
                    return false;
                }
                return false;
            }

            for (let i = 0; i < inps.length; i++) {
                let corr = () => {
                    if (isCorrect()) {
                        this.setState({ enviarBoton: true });
                    } else {
                        this.setState({ enviarBoton: false });
                    }
                }
                inps[i].onkeyup = corr;
                inps[i].onpaste = corr;
                inps[i].oninput = corr;
            }
        }
        formularioState();

    }

    enviarEmail() {
        for (let i = 0; i < this.state.formulario.length; i++) {
            this.state.formulario[i].value = "";
        }
        this.setState({enviarBoton: false});

        document.getElementById("notificacion-contactos-2")!.style.display = "block";
        setTimeout(() => {
            document.getElementById("notificacion-contactos-2")!.style.display = "block";
        }, 2000);
    }

    datosForm(){
        let nombre = document.getElementById("nombre_datos-form") as HTMLInputElement;
        let correo = document.getElementById("correo_datos-form") as HTMLInputElement;
        let celular = document.getElementById("celular_datos-form") as HTMLInputElement;
        let curso = document.getElementById("curso_datos-form") as HTMLInputElement;
        return {
            nombre: nombre.value,
            curso: curso.value,
            correo: correo.value,
            celular: celular.value
        }
    }

    componentDidUpdate(){
        if(!this.state.enviarBoton){
            addClassName("boton-dark-little", "disabled");
        }else{
            removeClassName("boton-dark-little", "disabled");
        }
    }

    render() {
        return <div className="seccion seccion-regular seccion-regular-formulario" id="formulario-inscribir" style={{ minHeight: this.state.seccionHeight + "px", backgroundColor: "var(--gray-1)" }}>
                <div>
                    <span className="slogan-contactos-down">CONTENIDO DE CALIDAD EN NUESTROS CURSOS</span>
                    <h1 className="title-contactos-down">¡APRENDE CON NOSOTROS!</h1>

                    <div className="formulario-contactos">
                        <Input id="nombre_datos-form" group="datos" title="NOMBRE" color="dark" />
                        <Input id="correo_datos-form" group="datos" title="CORREO" color="dark" />
                        <Input id="celular_datos-form" group="datos" title="TELEFONO O CELULAR" color="dark" />
                        <Input id="curso_datos-form" group="datos" title="CURSO" color="dark" />

                    </div>

                    <Notificacion texto="¡Gracias por contactarte con nosotros :)!" id="notificacion-contactos-2" />

                    <FirebaseDatabaseMutation path={"form-inscribir"} type="push">
                        {({runMutation})=>{
                            return <button className={"boton-dark-little"} 
                                onClick={async () => {
                                    //Enviar solo si tiene los campos llenos
                                    if (this.state.enviarBoton) {
                                        //Enviar EMAIL
                                        await runMutation(this.datosForm());
                                        this.enviarEmail();
                                    }
                            }}>ENVIAR</button>
                        }
                        }
                    </FirebaseDatabaseMutation>                    
                </div>
            </div>
    }
}
export default FormCurso;