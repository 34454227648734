import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, LargeInput, SelectInput } from "../components/Comps";
import { post, toastError } from "../theme/variables";

import * as EmailValidator from 'email-validator';

import "./Cotizacion.scss";
import { fbSvg } from "../components/Elements";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { FetchCustom, madePostAppWrite } from "../common/post";

//Formulario de cotizacion

let Cotizacion = () => {
    const site_key = "6Ld7dgMgAAAAAMs6TS-eZgWQY9wrwJQnn9XOzQZO";

    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [inputsFilled, setInputsFilled] = useState(false);
    const [enabledToSend, setEnabledToSend] = useState(false);

    const [token, setToken] = useState("123");
    const [sendedCot, setSendedCot] = useState(false);

    const { t } = useTranslation();

    let getTargets = () => {
        let name = document.getElementById("nombre-cotizacion") as HTMLInputElement;
        let email = document.getElementById("correo-cotizacion") as HTMLInputElement;
        let nameBussiness = document.getElementById("empresa-cotizacion") as HTMLInputElement;
        let tel = document.getElementById("telefono-cotizacion") as HTMLInputElement;
        let country = document.getElementById("pais-cotizacion") as HTMLInputElement;
        let city = document.getElementById("ciudad-cotizacion") as HTMLInputElement;
        let servicesBussiness = document.getElementById("des-cotizacion") as HTMLInputElement;
        let proyectType = document.getElementById("proyect-type-cotizacion") as HTMLSelectElement;
        let serviceNedded = document.getElementById("service-type-cotizacion") as HTMLSelectElement;
        let detailProyect = document.getElementById("details-cotizacion") as HTMLTextAreaElement;
        let timeLimit = document.getElementById("time-limit-cotizacion") as HTMLSelectElement;
        let budget = document.getElementById("budget-cotizacion") as HTMLSelectElement;
        let contactMode = document.getElementById("contact-type-cotizacion") as HTMLSelectElement;
        let howKnow = document.getElementById("howknow-cotizacion") as HTMLSelectElement;
        let policy = document.getElementById("privacy-policy") as HTMLInputElement;

        let allElements = [name,
            email,
            nameBussiness,
            tel,
            country,
            city,
            servicesBussiness,
            proyectType,
            serviceNedded,
            detailProyect,
            timeLimit,
            budget,
            contactMode,
            howKnow,
            policy
        ];

        return {
            allElements: allElements, name: name, email: email, nameBussiness: nameBussiness, telephone: tel,
            country: country, city: city, servicesBussiness: servicesBussiness, proyectType: proyectType, serviceNedded: serviceNedded,
            detailProyect: detailProyect, timeLimit: timeLimit, budget: budget, contactMode: contactMode, howKnow: howKnow, policy: policy
        };
    }

    let controlAllComplete = () => {

        let allElements = getTargets().allElements;
        let email = getTargets().email;

        if (allElements.filter(el => el.value.length === 0).length === 0 && EmailValidator.validate(email.value)) {
            setInputsFilled(true);
        } else {
            setInputsFilled(false);

        }
    }

    let send = () => {
        let elems = getTargets();

        let datos: Object;
        let form = new FormData();

        form.append("key", "add-budget");
        form.append("name", elems.name.value);
        form.append("email", elems.email.value);
        form.append("bussiness-name", elems.nameBussiness.value);
        form.append("telephone", elems.telephone.value);
        form.append("country", elems.country.value);
        form.append("city", elems.city.value);
        form.append("bussiness-services", elems.servicesBussiness.value);
        form.append("proyect-type", elems.proyectType.value);
        form.append("service-type", elems.serviceNedded.value);
        form.append("proyect-details", elems.detailProyect.value);
        form.append("limit-time", elems.timeLimit.value);
        form.append("budget", elems.budget.value);
        form.append("contact-method", elems.contactMode.value);
        form.append("how-know", elems.howKnow.value);

        form.append("recaptcha-token", token);

        //Zoho
        form.append("zf_referrer_name", "")
        form.append("zf_redirect_url", "")
        form.append("zc_gad", "")

        form.append("SingleLine", elems.name.value);
        form.append("Email", elems.email.value);
        form.append("SingleLine1-name", elems.nameBussiness.value);
        form.append("PhoneNumber_countrycode", elems.telephone.value);
        form.append("SingleLine2", elems.country.value);
        form.append("SingleLine3", elems.city.value);
        form.append("SingleLine4", elems.servicesBussiness.value);
        form.append("SingleLine5", elems.proyectType.value);
        form.append("SingleLine6", elems.serviceNedded.value);
        form.append("SingleLine7", elems.detailProyect.value);
        form.append("SingleLine8", elems.timeLimit.value);
        form.append("SingleLine9", elems.budget.value);
        form.append("SingleLine10", elems.contactMode.value);
        form.append("SingleLine11", elems.howKnow.value);
        form.append("SingleLine12", token);

        datos = {
            "key": "add-budget",
            "name": elems.name.value,
            "email": elems.email.value,
            "bussiness-name": elems.nameBussiness.value,
            "telephone": elems.telephone.value,
            "country": elems.country.value,
            "city": elems.city.value,
            "bussiness-services": elems.servicesBussiness.value,
            "proyect-type": elems.proyectType.value,
            "service-type": elems.serviceNedded.value,
            "proyect-details": elems.detailProyect.value,
            "limit-time": elems.timeLimit.value,
            "budget": elems.budget.value,
            "contact-method": elems.contactMode.value,
            "how-know": elems.howKnow.value,

            "recaptcha-token": token,
        }

        /*
                FetchCustom(
                    "https://forms.zohopublic.com/datasolucionesensoftware/form/Budget/formperma/Paqns73EEWwqYWGAbawWSM96DSBNvGa6WoL8LdmiEkE/htmlRecords/submit",
                    {
                        headers: {
                            "Content-Encoding": "multipart/form-data",
                            "Accept": "*",
                            "User-Agent": "*",
                            "Allow": "*",
                            "Allow-Origin": "*",
                            "Access-Control-Allow-Origin": "*"
                        }
                    },
                    "POST",
                    form)
                    .then((r) => {
                        let res = r as { result: boolean, exception?: string };
        
                        if (res.result) {
                            setSendedCot(true);
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        } else {
                            toastError(t('budgets.errors.error_base').concat(res.exception!));
                            setSendedCot(false);
                            setEnabledToSend(true);
                        }
                    }).catch((e: any) => {
                        toastError(t('budgets.errors.try_again'));
                        setSendedCot(false);
                        setEnabledToSend(true);
                    })
        */
        madePostAppWrite(
            datos,
            "forms",
            "form-budget"
        )
    }

    useLayoutEffect(() => {
        try {
            controlAllComplete();
        } catch (ev: any) {
            console.error("Markup-error")
        }
    }, [privacyPolicy])

    useEffect(() => {
        setEnabledToSend(inputsFilled && privacyPolicy && token !== "");
    }, [privacyPolicy, inputsFilled, token]);

    return <>
        <form className="cotizacion-page px-auto py" action='https://forms.zohopublic.com/datasolucionesensoftware/form/Budget/formperma/Paqns73EEWwqYWGAbawWSM96DSBNvGa6WoL8LdmiEkE/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' encType='multipart/form-data'>

            <Helmet>
                <title>{t('budget.page_name')}</title>
                <meta name="description" content="DATA Soluciones en Software | Cotizamos tu proyecto | Quieres tener una página web, aplicacion móvil o software pero no tienes una idea del precio, utiliza nuestra herramienta de cotizaciones para conocer el precio y posibilidades de tu proyecto de software" />
                <meta property="og:url" content="https://serviciosdata.com/cotizacion" />
                <meta property="og:title" content="DATA Soluciones en Software" />
                <meta property="og:description" content="Cotizamos tu proyecto web, app móvil u otro" />
                <meta property="og:image" content="https://serviciosdata.com/assets/DATA-transparent-sin-slogan.png" />
            </Helmet>

            <input type="hidden" name="zf_referrer_name" value="" />
            {/* <!-- To Track referrals , place the referrer name within the " " in the above hidden input field --> */}
            <input type="hidden" name="zf_redirect_url" value="https://serviciosdata.com/success-budget" />
            {/* <!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field --> */}
            <input type="hidden" name="zc_gad" value="" />
            {/* <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM --> */}

            <>
                <div className="title-seccion-cotizacion">
                    <h3>{t('budget.title')}</h3>

                    <span>{t('budget.description')}</span>
                </div>

                <div>
                    <Input name="SingleLine" title={t('budget.entries._1.placeholder')} id="nombre-cotizacion" color="light" onInput={controlAllComplete} />
                    <Input name="Email" title={t('budget.entries._2.placeholder')} id="correo-cotizacion" color="light" onInput={(ev: any) => {

                        controlAllComplete();

                    }} onBlur={(er: any) => {
                        if (!EmailValidator.validate(er.target.value)) {
                            toastError(t('budget.errors.invalid_email'));
                        }
                    }} />
                </div>

                <div>
                    <Input name="SingleLine1" title={t('budget.entries._3.placeholder')} id="empresa-cotizacion" color="light" onInput={controlAllComplete} />
                    <Input name="PhoneNumber_countrycode" compname="PhoneNumber" title={t('budget.entries._4.placeholder')} id="telefono-cotizacion" color="light" onInput={controlAllComplete} />
                </div>

                <div>
                    <Input name="SingleLine2" title={t('budget.entries._5.placeholder')} id="pais-cotizacion" color="light" onInput={controlAllComplete} />
                    <Input name="SingleLine3" title={t('budget.entries._6.placeholder')} id="ciudad-cotizacion" color="light" onInput={controlAllComplete} />
                </div>

                <div>
                    <Input name="SingleLine4" title={t('budget.entries._7.placeholder')} id="des-cotizacion" color="light" onInput={controlAllComplete} />
                </div>

                <div>
                    <SelectInput name="SingleLine5" title={t('budget.entries._8.placeholder')} options={[
                        { key: "web", text: t('budget.entries._8.options._1') },
                        { key: "app", text: t('budget.entries._8.options._2') },
                        { key: "desktop", text: t('budget.entries._8.options._3') },
                    ]}
                        id="proyect-type-cotizacion"
                        other={true}
                        onInput={controlAllComplete}
                    />
                    <SelectInput name="SingleLine6" title={t('budget.entries._9.placeholder')} options={[
                        { key: "new", text: t('budget.entries._9.options._1') },
                        { key: "edit", text: t('budget.entries._9.options._2') },
                    ]}
                        id="service-type-cotizacion"
                        other={true}
                        onInput={controlAllComplete}
                    />
                </div>

                <div>
                    <LargeInput name="SingleLine7" title={t('budget.entries._10.placeholder')} id="details-cotizacion" color="light" onInput={controlAllComplete} />
                </div>

                <div>
                    <SelectInput name="SingleLine8" title={t('budget.entries._11.placeholder')} id="time-limit-cotizacion"
                        options={[
                            { key: "without-time-limit", text: t('budget.entries._11.options.no') }
                        ]}
                        other
                        otherText={t('budget.entries._11.options.yes_text')}
                        otherOptionText={t('budget.entries._11.options.yes')}
                        onInput={controlAllComplete} />

                    <SelectInput name="SingleLine9" title={t('budget.entries._12.placeholder')}
                        id="budget-cotizacion"
                        options={[
                            { key: "without-budget-limit", text: t('budget.entries._12.options.no') }
                        ]}
                        other
                        otherText={t('budget.entries._12.options.yes_text')}
                        otherOptionText={t('budget.entries._12.options.yes')}
                        onInput={controlAllComplete} />
                </div>

                <div>
                    <SelectInput name="SingleLine10" title={t('budget.entries._13.placeholder')} options={[
                        { key: "wpp", text: t('budget.entries._13.options._1') },
                        { key: "email", text: t('budget.entries._13.options._2') },
                        { key: "phone", text: t('budget.entries._13.options._3') },
                    ]}
                        id="contact-type-cotizacion"
                        other={true}
                        onInput={controlAllComplete} />

                    <SelectInput name="SingleLine11" title={t('budget.entries._14.placeholder')} options={[
                        { key: "google", text: t('budget.entries._14.options._1') },
                        { key: "facebook", text: t('budget.entries._14.options._2') },
                        { key: "recomended", text: t('budget.entries._14.options._3') },
                    ]}
                        id="howknow-cotizacion"
                        other={true}
                        onInput={controlAllComplete} />
                </div>

                <div className="policy-budget">
                    <input type="checkbox" id="privacy-policy" defaultChecked={privacyPolicy} onChange={(ev: any) => {
                        setPrivacyPolicy(ev.target.checked);
                    }} />

                    <span>{t('budget.policy.text_1')}<Link className="text-bg-gradient" to="/politica-de-privacidad">{t('budget.policy.link')}</Link>{t('budget.policy.text_2')}</span>
                </div>

                <ReCAPTCHA
                    sitekey={site_key}
                    onChange={(token) => {
                        setToken(token!);
                    }}
                    onExpired={() => {
                        setToken("");
                    }}
                />

                <Button type={"submit"} disabled={!enabledToSend} text={t('budget.btn_send_budget.text')} onClick={() => {
                    //send();
                    //setEnabledToSend(false)
                }} />
            </>
        </form>
    </>
}
export default Cotizacion;