
import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

import "./PoliticaDePrivacidad.scss";

export let PoliticaDePrivacidadPioPio = () => {
    return <div className="policy-page">
        <div>

            <img src="/assets/DATA-transparent-sin-slogan.png" alt="DATA Soluciones en Software | Logo" />

            <h3>POLÍTICA DE PRIVACIDAD | PioPio App</h3>

            <p></p>

            <strong>Qué información personal recopilamos</strong>

            <p>
                Como usuario de nuestro Sitio o Aplicación, NO recopilamos información acerca de tu actividad o sobre tí más que la necesaria para mantener un estado saludable de nuestros servidores y evitar de esta forma su sobre saturación, en este sentido se utilizarán herramientas de terceros como ReCaptcha de Google.
            </p>

            <strong>
                Revisión de la Política de Privacidad
            </strong>

            <p>
                DATA Soluciones en Software y la aplicación PioPio se reserva el derecho de cambiar, modificar o revisar esta Política de privacidad para cumplir con las leyes aplicables, para reflejar cambios en nuestros procesos o por cualquier otro motivo en cualquier momento. También te recomendamos que consulten la Política de privacidad periódicamente para leer sobre cualquier cambio, modificación o revisión.
            </p>

            <strong>Contáctenos</strong>

            <p>
                Todas las solicitudes para conocer más sobre esta Política de privacidad deben dirigirse a los números de celular o correos descritos en nuestra sección <b>Contactos</b>. Nos esforzaremos por responder a su consulta lo más rápido posible.

                Responderemos a todas las solicitudes, consultas o inquietudes dentro de los quince (15) días.
            </p>
        </div >
    </div >
}

let PoliticaDePrivacidad = () => {

    const { t } = useTranslation();

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return <div className="policy-page">
        <Helmet>
            <title>{t('privacy_policy.page_name')}</title>
        </Helmet>
        <div>

            <img src="/assets/DATA-transparent-sin-slogan.png" alt="DATA Soluciones en Software | Logo" />

            <h3>{t('privacy_policy.title')}</h3>

            <p>{t('privacy_policy.description')}</p>

            <strong>{t('privacy_policy.paragraphs._1.subtitle')}</strong>

            <p>{t('privacy_policy.paragraphs._1.text')}</p>

            <strong>{t('privacy_policy.paragraphs._2.subtitle')}</strong>

            <p>{t('privacy_policy.paragraphs._2.text')}</p>

            <strong>{t('privacy_policy.paragraphs._3.subtitle')}</strong>

            <p>{t('privacy_policy.paragraphs._3.text')}</p>

            <strong>{t('privacy_policy.paragraphs._4.subtitle')}</strong>

            <p>{t('privacy_policy.paragraphs._4.text')}</p>

            <strong>{t('privacy_policy.paragraphs._5.subtitle')}</strong>

            <p>{t('privacy_policy.paragraphs._5.text')}</p>
        </div>
    </div>
}

export default PoliticaDePrivacidad;