
import axios from "axios";

import { Client, Databases } from "appwrite";

export const appWriteBase = "http://panel.solucionesdata.com:160/";
export const appWriteDB = (dbId: string, collectionid: string) => {
    return `v1/databases/${dbId}/collections/${collectionid}/documents`;
}

export let madePostAppWrite = (values: Object, db: string, coll: string) => {
    const client = new Client();

    const databases = new Databases(client);

    client
        .setEndpoint(appWriteBase + appWriteDB(db, coll)) // Your API Endpoint
        .setProject('study') // Your project ID
        ;

    const promise = databases.createDocument(db, coll, 'unique()', values);

    promise.then(function (response) {
        console.log(response); // Success
    }, function (error) {
        console.log(error); // Failure
    });
}


export const FetchCustom = (to: string, options: any, type: "POST" | "GET", data: any) => {
    return new Promise<any>((res, rej) => {
        fetch(to, {
            method: type? type : 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data // body data type must match "Content-Type" header
        }
        ).then(r=>{
            res(r)
        }).catch(err=>{
            rej(err)
        })
    });
}