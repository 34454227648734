import React from "react";

import "./CursoTarget.css";
import { dot } from "../Elements";

/**
 *  RECIBE: 
 *      codigo: string
 *      titulo: string
 *      docente: string
 *      descripcion: string
 *      finalizado: number (0: Pendiente, 1: En curso, 2: Finalizado)
 *      fecha_inicio: string
 *      fecha_fin: string
 *      img: string
 *      docente_img: string
 *      precio: number(DOLARES)
 *      duracion: number(CLASES)
 *      tiempo: number(HORAS)
 *      dificultad: number(0: principiante, 1:intermedio, 2: avanzado)
 *      skills: string(SEPARADOS POR ;)
 *      descripcion: string 
 */
const CursoTarget = (props: { curso: any, accion: any }) => {

    function comprar() {
        //window.location.href = "/cursos/comprar/"+props.curso.codigo;
        props.accion.comprar(props.curso);
    }
    function ver() {
        //window.location.href = "/cursos/"+props.curso.codigo;
        props.accion.ver(props.curso);
    }
    function estado() {
        let est = props.curso.finalizado;

        let comp = <div></div>

        switch (est) {
            case 0: comp = <div className="curso-estado-element estado-curso-pendiente">{dot}<span className="">Comienza el {props.curso.fecha_inicio}</span></div>
                break;
            case 1: comp = <div className="curso-estado-element estado-curso-encurso">{dot}<span className="">En curso</span></div>
                break;
            case 2: comp = <div className="curso-estado-element estado-curso-finalizado">{dot}<span className="">Finalizado</span></div>
                break;
            default: comp = <div className="curso-estado-element estado-curso-finalizado">{dot}<span className="">Finalizado</span></div>
                break;
        }
        return comp;
    }
    function precio() {
        let pr = <div className="curso-estado-precio" onClick={() => { comprar() }}><span>{props.curso.precio} Bs</span></div>;

        if (props.curso.precio == 0) {
            pr = <div className="curso-estado-precio" onClick={() => { comprar() }}><span>Gratis</span></div>
        }
        return pr;
    }

    return <div className="curso-target">
        <img className="curso-img-principal" onClick={() => { ver() }} src={props.curso.img} alt={props.curso.titulo} />
        <div className="curso-target-info">
            <span className="curso-target-titulo" onClick={() => { ver() }}>{props.curso.titulo}</span>
            <div className="curso-target-docente">
                {/*<img src={props.curso.docente_img} alt={props.curso.docente}/>*/}
                <span>Un curso de {props.curso.docente}</span>
            </div>
            <span className="curso-target-descripcion">{props.curso.skills}</span>
            <div className="curso-target-estado">
                {precio()}
                {estado()}
            </div>

        </div>
    </div>

}
export default CursoTarget;