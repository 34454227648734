import React from "react";
import {Document, Page, pdfjs} from "react-pdf";
import { screen } from "../../theme/variables";

class PDFReader extends React.Component<{ archivo: string }, {screen: number[], sizeOf: number}>{
    constructor(props: any){
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        
        this.state = {screen: screen, sizeOf: 0};
    }
    onDocumentLoadSuccess() {
    }

    componentDidMount(){
        //<div class="react-pdf__message react-pdf__message--loading">Loading PDF…</div>
    }

    render() {
        return (
            <Document file={this.props.archivo} onLoadSuccess={()=>{}} options={{ workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`}}>
                <Page pageNumber={1} />
            </Document>
        );
    }
}
export default PDFReader;