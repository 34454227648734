import { t } from "i18next";
import { fbSvg } from "../components/Elements";
import "./Cotizacion.scss";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Comps";

const SuccessSendedBudget = () => {
    const {t} = useTranslation();
    
    return <div className="cotizacion-page px-auto py">
        <div className="sended-cotizacion">
            <div className="title-seccion-cotizacion">
                <h3>{t('budget.correct.title')}</h3>

                <span>{t('budget.correct.subtitle')}</span>
            </div>

            <a href="https://www.facebook.com/DATASolucionesEnSoftware">
                {fbSvg}
            </a>

            <span style={{ margin: "1em 0" }}>{t('budget.correct.or')}</span>

            <Button text={t('budget.correct.return_home')} onClick={() => {
                window.location.href = "https://serviciosdata.com";
            }} />
        </div>
    </div>
}

export default SuccessSendedBudget;