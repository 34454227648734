import React from "react";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

import { Link } from "react-router-dom";
import { Label, RedesMin, Separator, Slogan } from "./Comps";

import "./Footer.css";

const Footer = () => {
    const {t} = useTranslation();

    return <footer className="">
        <div className="seccion-1-footer">
            <div className="footer-links footer-links-1">
                <Slogan />
                <RedesMin />
            </div>
            <div className="footer-container-areas">
                <div className="footer-links footer-areas footer-links-2">
                    <Label texto={t('footer.support_section.title')} tamaño="4" color="var(--white-6)" />
                    <ul className="footer-ul">
                        <li><Link to="/contactos">{t('footer.support_section.link_1')}</Link></li>
                        <li><Link to="/nosotros">{t('footer.support_section.link_2')}</Link></li>
                    </ul>
                </div>
                <div className="footer-links footer-areas footer-links-3">
                    <Label texto={t('footer.data_section.title')} tamaño="4" color="var(--white-6)" />
                    <ul className="footer-ul">
                        <li><Link to="/nosotros">{t('footer.data_section.link_1')}</Link></li>
                        <li><Link to="/servicios">{t('footer.data_section.link_2')}</Link></li>
                        {/* <li><Link to="/cursos">{t('footer.data_section.link_3')}</Link></li> */}
                        <li><Link to="/politica-de-privacidad">{t('footer.data_section.link_4')}</Link></li>
                        <li><Link to="/politica-de-privacidad/PioPio">{t('footer.data_section.link_5')}</Link></li>
                    </ul>
                </div>

            </div><div className="footer-links footer-areas footer-links-3">
                <Label texto={t('footer.contact_section.title')} tamaño="4" color="var(--white-6)" />
                <ul className="footer-ul">
                    <li><a href="mailto:soporte@serviciosdata.com">{t('footer.contact_section.email')}</a></li>
                    <li><a href="https://api.whatsapp.com/send?phone=59177718112">{t('footer.contact_section.cellphone')}</a></li>
                </ul>
            </div>
        </div>
        <Separator color="var(--white-5)" />
        <div className="seccion-2-footer">
            <span>{t('footer.rights.text')}</span>
        </div>
    </footer>

}
export default Footer;
