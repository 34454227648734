import React, { useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

import { Link, useNavigate } from "react-router-dom";
import { BeneficiosDeSoftware, OtrasEmpresas, Servicios } from "../components/Comps";
import FormContact from "../components/mini_componentes/FormContact";
import { seccionHeight } from "../theme/variables";

import "./Inicio.css";

let Inicio = () => {
    const [sectionHeight, setSectionHeight] = useState(0);

    const navigate = useNavigate();
    const {t} = useTranslation();

    useLayoutEffect(() => {
        setSectionHeight(seccionHeight());
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return (<div >
        <Helmet>
            <title>{t('home.page_name')}</title>
            <meta name="description" content="DATA Soluciones en Software - Bolivia - Empresa de Software, desarrollo de Software, creación de páginas web y aplicaciones móviles | 
                                Diseñamos y desarrollamos tu página web, aplicación móvil o software a medida para incrementar el potencial y alcance de tu emprendimiento" />
            <link rel="canonical" href="https://serviciosdata.com" />
        </Helmet>

        <div className="inicio-container-sup-component inicio-seccion-1" style={{ minHeight: sectionHeight + 50 + "px", overflowX: "hidden" }}>
            <h1 className="inicio-title-component">{t('home.sup.title')}</h1>
            <h3 className="inicio-subtitulo-component">{t('home.sup.description')}</h3>

            <div className="inicio-sup-buttons">
                <button className="inicio-contactanos-component" onClick={() => {
                    navigate("/contactos");
                }}>{t('home.sup.btn_contact')}</button>

                <div className="header-list-element">
                    <Link to="/cotizacion" >{t('home.sup.btn_budget')}</Link>
                </div>
            </div>

        </div>
        
        <Servicios />

        <BeneficiosDeSoftware />

        <OtrasEmpresas />

        <FormContact />
    </div>);

}
export default Inicio;