import React from "react";
import { Logo, SidebarMenu } from "./Comps";
import { MenuElements } from "./Elements";

import "./Header.css";

const Header = () => {
    return <div className="header-component">
        <Logo />

        <ul className="header-list-component">
            <MenuElements className="header-list-element" />
        </ul>
        <SidebarMenu controls="sidemenu-list-element" />
    </div>

}
export default Header;