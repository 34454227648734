export const TRANSLATION_EN = {
    //COMPONENTS
    header: {
        name: "DATA Software Solutions",
        home: "Home",
        name_data: "DATA",
        name_sols: "Soluciones en Software",
        services: "Services",
        contacts: "Contacts",
        us: "About us"
    },
    contact_form_work: {
        title: "LET'S WORK TOGETHER!",
        subtitle: "EFFICIENCY AND QUALITY IN EACH PROJECT",
        notifications: {
            on_submit: "Thank you for contacting us :)!",
            on_submit_error: {
                invalid_email: "Enter a valid email address"
            }
        },
        inputs: {
            input_1: {
                placeholder: "NAME"
            },
            input_2: {
                placeholder: "POSITION"
            },
            input_3: {
                placeholder: "ORGANIZATION"
            },
            input_4: {
                placeholder: "EMAIL"
            },
            input_5: {
                placeholder: "TELEPHONE"
            },
            input_6: {
                placeholder: "SUBJECT"
            },
            input_7: {
                placeholder: "MORE DETAILS"
            },
        },
        btn_submit: {
            text: "SEND"
        }
    },
    footer: {
        left_section: {
            title: "DATA",
            subtitle: "Software Solutions"
        },
        support_section: {
            title: "Support",
            link_1: "Contact us",
            link_2: "About us"
        },
        data_section: {
            title: "DATA",
            link_1: "About DATA",
            link_2: "Services",
            link_3: "Courses",
            link_4: "Privacy and use policy",
            link_5: "Privacy and use policy PioPio",
        },
        contact_section: {
            title: "Contacts",
            email: "soporte@serviciosdata.com",
            cellphone: "+591 77718112"
        },
        rights: {
            text: "2022 DATA - All rights reserved"
        }
    },

    //VARIABLES

    btn_contact_us: "Contact us",

    services: {
        card_1: {
            title: "Custom Software",
            category: "Highly specialized",
            description: "Software development according to your needs and requirements",
            on_hover: "CONTACT US",
            skills: {
                _1: "Highly customized software, completely focused on meeting your needs",
                _2: "Get the latest technology with our services",
                _3: "Support 24 Hours a day"
            }
        },
        card_2: {
            title: "Web Pages",
            category: "Efficient and scalable",
            description: "We create your own web portal and give you virtual presence",
            on_hover: "CONTACT US",
            skills: {
                _1: "Adaptive Web Pages",
                _2: "You will have an exclusive domain",
                _3: "Efficient and fast servers",
                _4: "We have multiple plans to suit your needs",
                _5: "We launch your brand to the world",
                _6: "Efficient, fast and customized"
            }
        },
        card_3: {
            title: "Multiplatform Mobile Applications",
            category: "Intuitive and safe",
            description: "Customized mobile applications",
            on_hover: "CONTACT US",
            skills: {
                _1: "Cross-platform hybrid mobile applications",
                _2: "Executables for IOS and Android",
                _3: "Time and budget efficient projects",
                _4: "Can also be uploaded to Play Store(Android) and App Store(IOS)",
                _5: "Robust, Reliable and Efficient"
            }
        },
        card_4: {
            title: "Design",
            category: "Attractiveness",
            description: "We design your Identity Manual",
            on_hover: "CONTACT US",
            skills: {
                _1: "We design your Logo with its different versions and with the style that best represents your brand",
                _2: "We create your Brand Manual",
                _3: "We base it on the values of your company",
                _4: "We create content for your networks"
            }
        },
        card_5: {
            title: "Marketing with Facebook ADS",
            category: "Excellent positioning",
            description: "Software development according to your needs and requirements",
            on_hover: "CONTACT US",
            skills: {
                _1: "More potential customers showing your products on Facebook",
                _2: "We promote your page or services on Facebook",
                _3: "Effective Marketing Strategies",
                _4: "Get closer to your followers or potential customers."
            }
        },
        card_6: {
            title: "Integral Consulting and Training",
            category: "Interactive",
            description: "We advise you on Software",
            on_hover: "CONTACT US",
            skills: {
                _1: "We advise you on the software you need according to your requirements and your budget",
                _2: "We teach you how to use the software you have and how to make your work more efficient",
                _3: "We offer training to the employees of your organization"
            }
        },
        card_7: {
            title: "Technical support and maintenance",
            category: "Available 24/7",
            description: "We offer you technical support and maintenance of the software you own",
            on_hover: "CONTACT US",
            skills: {
                _1: "We add the functionalities you are interested in to your existing software",
                _2: "Keep you and your software safe from unforeseen events, we can offer you technical support 24 hours a day, 7 days a week",
                _3: "Update your software with the latest technologies and security patches",
                _4: "We ensure the integrity of your data"
            }
        },
    },
    benefits: {
        _1: {
            title: "Custom Software",
            advantages: {
                _1: "You can automate repetitive processes and increase your productivity"
            }
        },
        _2: {
            title: "Web Page",
            advantages: {
                _1: "Present your products or services to the whole world and get ahead of your competition"
            }
        },
        _3: {
            title: "Mobile Applications",
            advantages: {
                _1: "Demonstrate your professionalism by presenting a mobile app to your consumers"
            }
        },
        _4: {
            title: "Design",
            advantages: {
                _1: "Attract more customers or followers through assertive and appealing designs"
            }
        },
        _5: {
            title: "Marketing with Facebook ADS",
            advantages: {
                _1: "Get more leads by introducing your products to a larger audience"
            }
        },
        _6: {
            title: "Integral Consulting and Training",
            advantages: {
                _1: "We offer you high quality technical training and consultancy"
            }
        },
        _7: {
            title: "Technical support and maintenance",
            advantages: {
                _1: "We are available 24 hours a day to provide you with prompt and appropriate solutions"
            }
        },
    },
    disponible_languages: {
        es: {
            name: "Spanish"
        },
        en: {
            name: "English"
        },
        fr: {
            name: "French"
        }
    },

    //PAGES

    home: {
        page_name: "DATA Software Solutions | Software Company",
        sup: {
            title: "Customized technical solutions",
            description: "We take care of giving a new corporate image to your business with the latest technology and the highest quality standards. Our software is highly reliable, robust and scalable",
            btn_contact: "Contact us",
            btn_budget: "Request a quote"
        },
        benefits: {
            cards: {
                card_1: {
                    title: "Custom Software",
                    description: "You can automate repetitive processes and increase your productivity."
                },
                card_2: {
                    title: "Web Page",
                    description: "Present your products or services to the world and get ahead of your competition."
                },
                card_3: {
                    title: "Mobile Applications",
                    description: "Demonstrate your professionalism by presenting a mobile application to your consumers"
                },
                card_4: {
                    title: "Design",
                    description: "Attract more customers or followers through assertive and appealing designs"
                },
                card_5: {
                    title: "Marketing with Facebook ADS",
                    description: "Get more potential customers by presenting your products to a larger audience"
                },
                card_6: {
                    title: "Integral Consulting and Training",
                    description: "We offer training and consulting in high quality technology."
                },
                card_7: {
                    title: "Technical support and maintenance",
                    description: "We are available 24 hours a day to offer you prompt and appropriate solutions."
                },
            },
            section: {
                title: "Benefits of working with us",
                btn_contact: "Contact us at"
            }
        },
        collaborations: {
            title: "WE HAVE WORKED WITH"
        }
    },
    service: {
        page_name: "DATA Software Solutions | Services",
        title: "SERVICES"
    },
    contacts: {
        page_name: "DATA Software Solutions | Contact Us",
        title: "OUR TEAM IS HERE TO SUPPORT YOU",
        subtitle: "Contact us",
        cellphone_section: {
            title: "Cellphone",
            numbers: {
                _1: "+591 77718112",
                _2: "+591 67188973"
            },
        },
        social_network_section: {
            title: "Our Networks",
        },
        email_section: {
            title: "Mail",
            emails: {
                _1: "soporte@serviciosdata.com",
                _2: "christiancopa@serviciosdata.com"
            }
        },
        subtext: "From DATA Software Solutions we are very happy to be one of the first Startups dedicated to Software Development and Brand Design in Bolivia."
    },
    about_us: {
        page_name: "DATA Software Solutions | About Us",
        sup: {
            title: "DATA Software Solutions",
            description: "We are a team of developers and designers, focused on the quality of our products and we offer to provide a renewed corporate image in order to add value to your brand with the highest level of human quality and productivity",
        },
        swiper: {
            title: "OUR VALUES AS AN ORGANIZATION",
            swipes: {
                _1: {
                    title: "MISSION",
                    description: "DATA seeks to maximize the potential of its customers through the use of the Internet by providing efficient technological solutions that also seek to increase their productivity."
                },
                _2: {
                    title: "VISION",
                    description: "To be one of the best Web Development companies, recognized by the innovation, simplicity and value generation of its solutions, with high productivity and human quality of its team."
                },
                _3: {
                    title: "OUR VALUES",
                    description: "",
                    skills: {
                        _1: {
                            title: "Honesty",
                            description: "Our products and services always meet the highest quality standards and specifications."
                        },
                        _2: {
                            title: "Responsibility",
                            description: "We assume the responsibility of always responding to the highest standards in the industry and always meeting the established deadlines."
                        },
                        _3: {
                            title: "Respect",
                            description: "We strive to empathetically understand all people in order to treat them with dignity and respect"
                        },
                        _4: {
                            title: "Commitment",
                            description: "We are always committed to meeting the expectations of our customers, students and people in general"
                        }
                    }
                }
            }
        }
    },
    budget: {
        page_name: "DATA Software Solutions | We quote your software project, mobile application, website or other",
        title: "Request your budget",
        description: "If you are interested in making modifications to your current website or have your own we can help you preparing your budget, to do this you can fill out the form below telling us some details of your project, it will only take a few minutes",
        entries: {
            _1: {
                placeholder: "Name"
            },
            _2: {
                placeholder: "Email"
            },
            _3: {
                placeholder: "Company"
            },
            _4: {
                placeholder: "Phone or cell phone"
            },
            _5: {
                placeholder: "Country"
            },
            _6: {
                placeholder: "City"
            },
            _7: {
                placeholder: "What type of products or services do you offer?"
            },
            _8: {
                placeholder: "What type of project is it?",
                options: {
                    _1: "Website",
                    _2: "Mobile application",
                    _3: "Desktop software",
                    other: "Other"
                }
            },
            _9: {
                placeholder: "What service do you need?",
                options:{
                    _1: "Development",
                    _2: "Maintenance",
                    other: "Other"
                }
            },
            _10: {
                placeholder: "Explain to us in detail the project you need."
            },
            _11: {
                placeholder: "Is there a deadline?",
                options: {
                    no: "No",
                    yes: "Yes",
                    yes_text: "Time limit in days"
                }
            },
            _12: {
                placeholder: "Do you have a budget already set?",
                options: {
                    no: "No",
                    yes: "Yes",
                    yes_text: "Enter your budget in dollars."
                }
            },
            _13: {
                placeholder: "How would you prefer to be contacted?",
                options: {
                    _1: "WhatsApp",
                    _2: "Email",
                    _3: "Phone",
                    other: "Other"
                }
            },
            _14: {
                placeholder: "How did you find out about DATA Software Solutions?",
                options: {
                    _1: "Google",
                    _2: "Facebook",
                    _3: "Third party recommendation",
                    other: "Other"
                }
            }
        },
        policy: {
            text_1: "I accept the ",
            text_2: " of DATA Software Solutions",
            link: "privacy policy"
        },
        correct:{
            title: "We are processing your quote",
            subtitle: "We received your information and an agent will quote your project, it might take some time so in the meantime you can check our Facebook page",
            or: "or",
            return_home: "Back to top"
        },
        btn_send_budget: {
            text: "Request your budget"
        },
        errors: {
            invalid_email: "Please enter a valid email",
            error_base: "An error has occurred generating your quote: ",
            try_again: "An error occurred generating your quote, please try again."
        },
        other_text: "Other"
    },
    privacy_policy: {
        page_name: "DATA Software Solutions | Privacy Policy",
        title: "PRIVACY POLICY",
        description: "This Privacy Policy sets forth the terms under which DATA Software Solutions uses and protects the information that is provided by its users when using its website. This company is committed to the security of its users' data. When we ask you to provide personal information by which you can be identified, we do so with the assurance that it will only be used in accordance with the terms of this document. However this Privacy Policy may change over time or be updated so we recommend and emphasize that you continually review this page to ensure that you agree to any such changes.        ",
        paragraphs: {
            _1:{
                subtitle: "Information that is collected",
                text: "Our web site may collect personal information such as: Name, contact information such as your email address and demographic information, etc. Also when necessary specific information may be required to process an order or make a delivery or billing.                "
            },
            _2:{
                subtitle: "Use of information collected",
                text: "Our website uses the information in order to provide the best possible service, particularly to keep track of users, to keep track of orders where applicable, and to improve our products and services. We may periodically send e-mails through our site with special offers, new products and other marketing information that we think may be relevant to you or may be of benefit to you, these e-mails will be sent to the address you provide and may be unsubscribed from at any time. DATA Software Solutions is highly committed to fulfilling our commitment to keeping your information secure. We use the most advanced systems and constantly update them to ensure that there is no unauthorized access to your personal information."
            },
            _3:{
                subtitle: "Cookies",
                text: "A cookie refers to a file that is sent with the purpose of requesting permission to be stored on your computer, by accepting such a file is created and the cookie then serves to have information regarding web traffic, and also facilitates and gives comfort to future visits to a recurring website. Another function that cookies have is that with them the web can recognize you individually and therefore provide you with a better and more personalized service. Our website uses cookies to identify the pages that are visited and their frequency. This information is used only for statistical analysis and then the information is permanently deleted. You can delete cookies at any time from your computer. However, cookies help to provide a better service from websites, they do not give access to information about your computer or you, unless you choose to provide it directly. You can accept or deny the use of cookies, however most browsers automatically accept cookies as it serves to have a better web service. You can also change the settings on your computer to decline cookies.                "
            },
            _4:{
                subtitle: "Third Party Links",
                text: "This web site may contain links to other sites that may be of interest to you. Once you click on these links and leave our site, we no longer have control over the site to which you are redirected and therefore we are not responsible for the terms or privacy or data protection of those other third party sites. Those sites are subject to their own privacy policies so it is recommended that you consult them to confirm that you agree with them.                "
            },
            _5:{
                subtitle: "Controlling your personal information",
                text: "At any time you may restrict the collection or use of personal information that is provided to our website. Whenever you are asked to fill out a form, such as a user registration form, you can check or uncheck the option to receive information by e-mail. In case you have checked the option to receive our newsletter or advertising you can cancel it at any time. \This company will not sell, give or distribute the personal information that is collected without your consent, unless required by a judge with a court order. Data Software Solutions reserves the right to change the terms of this Privacy Policy at any time"
            },
        }
    }
}