import { useState } from "react";

import "./Comps.css";

import { check, closeSvg, disponibleLanguages, dots, medios, MenuElements, menuSvg, redesElements, redirect, servicios, ventajas } from "./Elements";
import { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper';

import "swiper/css/bundle"

import { copyToClipboard, propertiesOf, screen, viewForm } from "../theme/variables";
import { Link } from "react-router-dom";
import { copyicon, languageicon, redirecticon } from "./mini_componentes/Svgs";

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import i18n from "i18next";

export function Logo() {
    const { t } = useTranslation();

    return <div className="logo-component" onClick={() => {
        window.location.href = "/";
    }}>
        <img alt="Logotipo DATA" src="/assets/svg/DATA-onlynube-orange.svg" />
        <span className="logo-data-component">{t('header.name_data')}</span>
        <span className="logo-slogan-component">{t('header.name_sols')}</span>
    </div>
}

/*  MENU SIDEBAR    */
export function SidebarMenu(datos: any) {
    const [cargado, setCargado] = useState(false);
    const [open, setOpen] = useState(datos["state"]);

    let ctrls = datos["controls"];

    let screen = [window.screen.width, window.screen.height];
    let d = (Math.sqrt(Math.pow(screen[0], 2) + Math.pow(screen[1], 2)) + 50) * 2;

    if (open) {
        //Sidebar abierto

        //Sidemenu width al 100%
        let side = document.getElementsByClassName("sidemenu-component")[0] as HTMLDivElement;
        side.style.width = "100%";
        side.style.height = "100%";

        //Calculado el tamaño de circulo negro
        let back = document.getElementById("sidemenu-background-component") as HTMLDivElement;
        back.style.width = d + "px";
        back.style.height = d + "px";
        back.style.margin = "-" + Math.round(d / 2) + "px -" + Math.round(d / 2) + "px 0 0";

        //Habilitado y mostrado
        let container = document.getElementById("sidemenu-container-component") as HTMLDivElement;
        setTimeout(() => {
            //.5s de transicion css
            container.style.display = "flex";
            setTimeout(() => {
                container.style.opacity = "1";
            }, 100);
        }, 100);

        //Espera de click
        let controls = document.getElementsByClassName(ctrls) as HTMLCollectionOf<HTMLLIElement>;
        for (let i = 0; i < controls.length; i++) {
            controls[i].onclick = () => {
                setOpen(false);
            }
        }

    } else if (cargado) {
        //Sidemenu width al 100%
        let side = document.getElementsByClassName("sidemenu-component")[0] as HTMLDivElement
        side.style.width = "unset";
        side.style.height = "unset";

        //Set background en 0
        let back = document.getElementById("sidemenu-background-component") as HTMLDivElement;
        back.style.width = "0";
        back.style.height = "0";
        back.style.margin = "-0 -0 0 0";

        //container esondiendo
        let container = document.getElementById("sidemenu-container-component") as HTMLDivElement;
        container.style.display = "none";
        container.style.opacity = "0";
    } else if (!open) {
        //Habilitado y mostrado
        let container = document.getElementById("sidemenu-container-component") as HTMLDivElement;

        if (container) {
            container.style.display = "none";
        }
    }

    if (cargado) {
        let list = document.getElementsByClassName("sidemenu-list-component")[0] as HTMLUListElement;
        list.style.transition = "transform .5s ease-out";

        let desplazar = () => {
            list.style.transform = "translateX(-150%)";
        }
        let volver = () => {
            list.style.transform = "translateX(0%)";
        }

        //AGREGAR AQUI MAS OYENTES A LOS BOTONES QUE REALIZEN DESPLAZAMIENTO
        try {
            let el1 = document.getElementsByClassName("list-icon-component")[1] as HTMLDivElement;
            el1.onmouseenter = desplazar;
            el1.onmouseleave = volver;
        } catch (e: any) {

        }

        //Cerrar element
        let cerrar = document.getElementsByClassName("volver-sidemenu-extra") as HTMLCollectionOf<HTMLSpanElement>;
        for (let i = 0; i < cerrar.length; i++) {
            cerrar[i].onclick = volver;
        }
    }

    useEffect(() => {
        if (!cargado) {
            setCargado(true);
        }

    }, [cargado]);

    return <div className="sidemenu-component">
        <div className="sidemenu-background-component" id="sidemenu-background-component" style={{ width: "0", height: "0", margin: "0 0 0 0" }}>
            {/*ENCARGADO DE REALIZAR LA TRANSICION DE FONDO NEGRO*/}
        </div>
        <div onClick={() => {
            setOpen(!open);
        }} className="sidemenu-icon-component">
            <svg viewBox="0 0 384.97 384.97" width="100%" height="100%" style={{ display: open ? "none" : "flex" }}>
                {menuSvg()}
            </svg>
            <svg viewBox="-50 -50 600 600" width="100%" height="100%" style={{ display: open ? "flex" : "none", fill: "white" }}>
                {closeSvg()}
            </svg>
        </div>

        <div className="sidemenu-container-component" id="sidemenu-container-component">
            <ul className="sidemenu-list-component">
                <MenuElements className="sidemenu-list-element" />
            </ul>
            {RedesSociales()}
        </div>

    </div>
}

/*      REDES SOCIALES      */
export function RedesSociales() {
    return <div className="redes-component">
        <ul>
            {redesElements()[0]}
            {redesElements()[1]}
        </ul>
    </div>
}


/*        TERMINOS         */


/*         DATA             */
export function Slogan() {
    const { t } = useTranslation();
    return <div className="slogan-component">
        <span>{t('header.name_data')}</span>
        <span>{t('header.name_sols')}</span>
    </div>
}

/*      REDES MIN       */
export function RedesMin() {
    return <ul className="redes-min-component">
        {redesElements()}
    </ul>
}


/*      LABELS      */
export function Label(datos: any) {
    let texto = datos["texto"];
    let size = datos["tamaño"];
    let color = "unset";
    if (datos["color"] !== undefined) {
        color = datos["color"];
    }

    return <span className={"label label-" + size} style={{ color: color }}>{texto}</span>
}


/*     SEPARATOR        */
export function Separator(datos: any) {
    let color = datos["color"];

    return <div className="separator-component" style={{ borderTopColor: (color !== undefined ? color : "black") }}></div>
}

/*      DESPLEGABLE LIST WITH ICON      */
//Enter PrincipalComp: HTMLElement Icon:HTMLElement ElementList:HTMLElement
export function ListWithIcon(datos: any) {
    let pcomp = datos["pcomp"];
    let list = datos["list"];

    return <div className="list-icon-component">
        {pcomp}
        <div className="list-icon-list-element">
            <div className="list-icon-list-in-element">
                {list}
                <span className="volver-sidemenu-extra" style={{ display: "none" }}>VOLVER</span>
            </div>
        </div>
    </div>
}

/*      SUBLINE         */
//Linea naranja regularmete colocada debajo de un titulo
export function SubLine() {
    return <div className="subline-component"></div>
}

/*      MENU POPUP      */
export function PopupMenu(datos: any) {
    const [open, setOpen] = useState(false);

    let subcomponent = datos["subcomponent"];

    return <div className="popupmenu-component">
        <div onClick={() => { setOpen(!open) }} className="popup-icon-element">
            {dots}
        </div>
        <div className="popup-subcomponent-element" style={{ display: open ? "block" : "none" }}>
            {subcomponent}
        </div>
    </div>
}

export function ContactarPopup(datos: any) {
    let click = datos["click"];
    return <div className="contactar-popup-button-component" onClick={click}>
        <span>CONTACTALO</span>
    </div>
}

/*      ENLACE A        */
export function RedirectTo(datos: any) {
    let to = datos["to"];
    return <div className="redirect-to-component" onClick={() => {
        window.location = to;
    }}>
        {redirect}
    </div>
}

/*      INPUT       */
//color : dark o light
export function Input(datos: any) {
    let title = datos["title"];
    let classname = datos["className"];
    let group = datos["group"];
    let id = datos["id"];
    let color = datos["color"];
    let name = datos["name"];

    return <div className={"input-component " + color} >
        <span className="input-title-component">{title}</span>
        <input name={name} type="text" className={classname + " " + group + " input-element-component"} id={id} onBlur={datos.onBlur} onFocus={datos.onFocus} onInput={datos.onInput} />
    </div>
}

/*      INPUT       */
//color : dark o light
export let LargeInput = (datos: any) => {
    let title = datos["title"];
    let classname = datos["className"];
    let group = datos["group"];
    let id = datos["id"];
    let color = datos["color"];
    let name = datos["name"];

    return <div className={"input-component textarea-input " + color}>
        <span className="input-title-component">{title}</span>
        <textarea name={name} className={classname + " " + group + " "} id={id} onBlur={datos.onBlur} onInput={datos.onInput} />
    </div>
}

/*      SELECT INPUT*/

export let SelectInput = (props: { title: string, options: { key: string, text: string }[], id: string, other?: boolean, otherText?: string, otherOptionText?: string, onBlur?: () => any, onInput?: () => any, name: any }) => {

    const [other, setOther] = useState(false);

    const { t } = useTranslation();

    return <div className="select-input-component input-component  light">
        <span className="input-title-component">{props.title}</span>
        <select id={other ? "" : props.id} name={props.name} onChange={(ev: any) => {
            if (props.onInput) {
                props.onInput();
            }

            if (ev.target.value === "other") {
                setOther(true);
            } else {
                setOther(false);
            }
        }} onBlur={props.onBlur}>
            {props.options.map((elem: { key: string, text: string }, index: number) => {
                return <option key={"option-select-" + props.id + "-" + index} value={elem.key}>{elem.text}</option>
            })}

            {props.other ?
                <option value="other">{props.otherOptionText ? props.otherOptionText : t('budget.other_text')}</option>
                :
                <></>
            }

        </select>

        {other ?
            <Input title={props.otherText ? props.otherText : t('budget.other_text')} id={props.id} color="light" onBlur={props.onBlur} onInput={props.onInput} />
            :
            <></>
        }

    </div>
}

/*      TEXTO NOTIFICACION      */
export function Notificacion(datos: any) {
    let id = datos["id"];
    let texto = datos["texto"];
    return <span id={id} className="notificacion-component">{texto}</span>
}



/*      LOADER          */
export function Loader(datos: any) {
    let style = datos["style"];
    return <div style={style} className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>;
}


/*      SPAN WITH ICON      */
export function TextWithIcon(datos: any) {
    let texto = datos["text"];
    let icon = datos["icon"];
    let style = datos["style"];
    return <div className={"text-with-icon-component"} style={style} >
        <span>{texto}</span>
        {icon}
    </div>
}

/*      COMPONENT WITH ICON      */
export function ComponentWithIcon(datos: any) {
    let comp = datos["component"];
    let icon = datos["icon"];
    let style = datos["style"];
    return <div className={"text-with-icon-component"} style={style}>
        {comp}
        {icon}
    </div>
}

/*      PAGE NOT FOUND      */
export function Page404() {
    return <div className="page-404" style={{ maxWidth: screen[0] + "px", height: screen[1] + "px", overflow: "hidden" }}>
        <h1>404</h1>
        <span>Ooops, no pudimos encontrar la página que solicitaste</span>
        <button className="boton-little" onClick={() => { window.location.href = "/" }}>VOLVER AL INICIO</button>
        <Nubes />
    </div>
}

/*      FONDO NUBES     */
export function Nubes() {
    return <div className="nubes" style={{ width: screen[0] + "px", height: screen[1] + "px", maxWidth: screen[0] + "px", maxHeight: screen[1] + "px" }}>
        <img src="/assets/svg/DATA-onlynube-white.svg" className="nube x5" style={{ zIndex: -2 }} />
        <img src="/assets/svg/DATA-onlynube-white.svg" className="nube x4" style={{ zIndex: -3 }} />
        <img src="/assets/svg/DATA-onlynube-white.svg" className="nube x3" style={{ zIndex: -4 }} />
        <img src="/assets/svg/DATA-onlynube-white.svg" className="nube x2" style={{ zIndex: -5 }} />
        <img src="/assets/svg/DATA-onlynube-white.svg" className="nube x1" style={{ zIndex: -6 }} />
    </div>
}

/*      SERVICIOS      */
export function Servicios() {
    let srv = servicios;
    let cards = [];

    for (let i = 0; i < srv.length; i++) {
        cards.push(<Card key={"card_service_key_".concat(i.toString())} info={srv[i]} index={i} />)
    }

    return <div className="servicios-component seccion-color-2">
        {cards}
    </div>
}

/*          SERVICIOS CARD          */
export function Card(datos: any) {
    const { t } = useTranslation();

    let key = datos.index + "-servicios-cards";
    let skill: string = datos.info.skill;
    let titulo: string = datos.info.titulo;
    let descripcion: string = datos.info.descripcion;
    let img = datos.info.img;

    let color = "var(--orange-1)";
    switch (datos.index) {
        case 1: color = "var(--verde-paleta)"; break;
        case 2: color = "var(--cafe-paleta)"; break;
        case 3: color = "var(--morado-paleta)"; break;
        case 4: color = "var(--violeta-paleta)"; break;
        case 5: color = "var(--verde-paleta)"; break;
        case 6: color = "var(--cafe-paleta)"; break;
        default: break;
    }

    return <div className="card-servicio-comp" key={key}>
        <div className="card-servicio-img">
            <img src={img} />
            <div className="card-img-text" onClick={() => { }}>
                <Link to="/servicios" className="card-hover-text">{t('services.card_1.on_hover')}</Link>
            </div>

        </div>

        <div className="card-servicio-datos">
            <span className="card-skill-datos" style={{ color: color }}>{t(skill)}</span>
            <span className="card-titulo-servicio-datos">{t(titulo)}</span>
            <span className="card-descripcion-servicio-datos">{t(descripcion)}</span>
        </div>
    </div>
}


/*      UNDERLINE            */
export function Underline() {
    return <div className="underline-component" style={{ height: "2px" }}>

    </div>
}

/*      CONTACTANOS COMPONENT       */
export let ButtonContactanos = (datos: any) => {
    const { t } = useTranslation();
    return <button className="contactanos-component" onClick={datos.onClick}>{t('btn_contact_us')}</button>;
}

/*      BUTTON          */
export let Button = (props: { text: string, disabled?: boolean, onClick?: () => any, type?: any }) => {
    return <button disabled={props.disabled ? props.disabled : false} type={props.type ? props.type : "button"} className="contactanos-component" onClick={props.onClick}>{props.text}</button>
}

/*          SWIPER          */
export let SwiperSlides = (datos: any) => {
    let datosSlides = servicios;

    const { t } = useTranslation();

    SwiperCore.use([Pagination, Navigation]);

    let contactanosbtn = (titulo: any) => {
        document.getElementById("formulario")?.scrollIntoView();
        let inp = document.getElementById("asunto_datos-form") as HTMLInputElement;
        inp.value = t(titulo);
    }

    const pagination = {
        "clickable": true,
        "renderBullet": (index: any, className: any) => {
            return '<div class=\"' + className + ' bullets-slides\">' +
                '<a className="servicios-indice-element text-bg-gradient-hover" href="' + datosSlides[index].id + '">' + t(datosSlides[index].titulo) + '</a>' +
                '</div>';
        }
    }

    return <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={pagination}
        className="swiper-slides-component seccion-color-2" >
        {datosSlides.map(({ titulo, skill, skills, img }, index) => (
            <SwiperSlide key={index + "-swiper-slide"} className="container-slide">
                <img src={img} />
                <div>
                    <div className="datos-slides">
                        <span className="titulo-slide text-bg-gradient">{t(titulo)}</span>
                        <span className="skill-slide">{t(skill)}</span>
                    </div>
                    <div className="mas-info-slides">
                        {skills.map((dato, index) => (
                            <TextWithIcon text={t(dato)} icon={check} key={index + "-skills"} />
                        ))}
                    </div>

                    <div className="btn-slides">
                        <ButtonContactanos onClick={() => { contactanosbtn(titulo) }} />
                    </div>

                </div>
            </SwiperSlide>
        ))}
    </Swiper>
}

/*          SWIPER GENERAL         */
export let SwiperDatos = (datos: any) => {
    let datosSlides = datos.datos as [{ titulo: any, id: any, elem: any, img: string }];
    let className = datos.className;
    SwiperCore.use([Pagination, Navigation]);

    const pagination = {
        "clickable": true,
        "renderBullet": (index: any, className: any) => {
            return '<div class=\"' + className + ' bullets-slides\">' +
                '<a className="servicios-indice-element text-bg-gradient-hover" href="' + datosSlides[index].id + '">' + datosSlides[index].titulo + '</a>' +
                '</div>';
        }
    }

    return <Swiper spaceBetween={50}
        slidesPerView={1}
        pagination={pagination}
        className={"swiper-slides-component seccion-color-2 " + className} >
        {datosSlides.map(({ titulo, id, elem, img }, index) => (
            <SwiperSlide key={index + "-swiper-slide"} className="container-slide">
                <img src={img} />
                {elem}
            </SwiperSlide>
        ))}
    </Swiper>
}


/*      EMPRESAS COLABORADORAS      */
export let OtrasEmpresas = () => {
    const [empresas, setEmpresas] = useState([
        { web: "https://eduserver.org", logo: "/assets/logos/eduserver.jpg" },
        { web: "https://redo2.org", logo: "/assets/logos/redo2.jpg" },
        { web: "https://propacha.org", logo: "/assets/logos/propacha.jpg" },
        { web: "https://recreabolivia.org", logo: "/assets/logos/recreabolivia.jpg" },
        { web: "#", logo: "/assets/logos/piopio.png" },
    ]) as any;

    const { t } = useTranslation();

    let RenderEmpresas = () => {
        let emps = [];
        for (let i in empresas) {
            emps.push(<div key={i + "-empresa"} className="card-empresa">
                <a href={empresas[i].web} target="_blank">
                    <img src={empresas[i].logo} className="card-empresa-img" width="90" height="90" />
                </a>
            </div>)
        }

        return <div className="empresas-portafolio-container">
            {emps}
        </div>;
    }

    return <div className="empresas-contactos">
        <span className="titulo-empresas-contactos">{t('home.collaborations.title')}</span>

        <RenderEmpresas />

    </div>
}

/**     BENEFICIOS DE UN SOFTWARE        */
export let BeneficiosDeSoftware = () => {
    const { t } = useTranslation();

    let beneficios = ventajas as [];

    return <div className="beneficios-software-component seccion-color-1">
        <div className="beneficios-software-right">
            {beneficios.map((({ titulo, ventajas }, index) => (
                <div key={"benefit_key_".concat(index.toString())} className="target-beneficio-software">
                    <span className="text-bg-gradient">{t(titulo)}</span>
                    <p>{t(ventajas)}</p>
                </div>
            )))}
        </div>

        <div className="beneficios-software-left seccion-bg-gradient-animation">
            <h1>{t('home.benefits.section.title')}</h1>
            <button className="inicio-contactanos-component" onClick={() => {
                viewForm("#formulario");
            }}>{t('home.benefits.section.btn_contact')}</button>
        </div>
    </div>
}

/*         VERTICAL LINE        */
export let VerticalLine = () => {
    return <div className="vertical-line-component">

    </div>
}

/*          CONTACTOS SITIOS        */
export let ContactosMedios = () => {
    const { t } = useTranslation();
    const meds = medios;

    return <div className="contactos-medios-component">

        {meds.map((elem, i) => (
            <>
                <div className="datos-contactos-medios">
                    {elem.icon}
                    <span className="datos-titulo-contactos-medios text-bg-gradient">{t(elem.titulo)}</span>

                    {elem.vals.map((elem: string, index: number) => (
                        <div className="datos-text-container-contactos" onClick={() => { copyToClipboard(elem) }}>
                            <span className="datos-text-contactos-medios" key={index + "-dato-contactos-medio-" + 0}>{t(elem)}</span>
                            {copyicon}
                        </div>
                    ))}

                    {elem.links.map(({ nombre, url }, index) => (
                        <div className="datos-url-container-contactos" onClick={() => { window.location.href = url }}>
                            <a href={url} className="datos-url-contactos-medios">{t(nombre)}</a>
                            {redirecticon}
                        </div>
                    ))}
                </div>

                {i === 0 || i === 1 ? <VerticalLine /> : <></>}
            </>
        ))}
    </div>
}

/*      HANGE LANGUAGE ICON     */
export let ChangeLanguageMenu = (props: { className?: string }) => {
    const { t } = useTranslation();

    const disponible_languages = disponibleLanguages;

    return <li className={"change-language-menu"}>
        {languageicon}
        <span>{i18n.language}</span>
        <ul className="desplegable-menu">
            {disponible_languages.map((element: { name: string, type: string }, index: number) => {
                return <li key={"disponible_language_key_".concat(index.toString())} className="header-list-element" onClick={() => {
                    i18n.changeLanguage(element.type)
                }}>
                    {t(element.name)}
                    <Underline />
                </li>
            })}
        </ul>
    </li>
}
