
import { toast } from "react-toastify";

export let screen = [window.screen.availWidth, window.screen.availHeight];

export let propertiesOf = (elem: any) => {
    /**
     * Return
     *  bottom: 177,
        height: 54.7,
        left: 278.5,​
        right: 909.5,
        top: 122.3,
        width: 631,
        x: 278.5,
        y: 122.3,

        of elem
     */
    return elem.getBoundingClientRect();
}

export let sizeOfId = (id: string) => {
    //return [width, height] of id element
    return [propertiesOf(document.getElementById(id)).width, propertiesOf(document.getElementById(id)).height];
}
export let sizeOfClass = (clase: string) => {
    //return [[width, height], ...] of class elements
    let vals = [];
    let elems = document.getElementsByClassName(clase);
    for (let i = 0; i < elems.length; i++) {
        vals[i] = [propertiesOf(elems[i]).width, propertiesOf(elems[i]).height];
    }
    return vals;
}
export let sizeOf = (elem: any) => {
    //Return [width, height] of elem
    return [propertiesOf(elem).width, propertiesOf(elem).height];
}

/*      SECCION HEIGHT      */
//Return el tamaño de la pantalla menos el header
export const seccionHeight = () => {
    return screen[1] - sizeOfClass("header-component")[0][1];
}

declare global {
    interface Window {
        opera: any;
    }
}

export function makeEvent(nombre: string, values: any, ev: any) {
    const evento = new CustomEvent(nombre, {
        bubbles: true,
        detail: values
    });
    ev.target!.dispatchEvent(evento);
}

export function createEvent(nombre: string) {
    let event = document.createEvent("Event");
    event.initEvent(nombre, true, true);
    document.dispatchEvent(event);
}

export function isClickOutOf(elem: any, fun: any, click: any) {
    document.addEventListener('mouseup', function (event) {
        if (!elem.contains(event.target)) {
            fun();
        } else {
            click();
        }
    });
}

export function addClassName(className: string, newClass: string) {
    let cl = document.getElementsByClassName(className) as HTMLCollection;
    for (let i = 0; i < cl.length; i++) {
        cl[i].classList.add(newClass);
    }
}
export function removeClassName(className: string, remClass: string) {
    let cl = document.getElementsByClassName(className) as HTMLCollection;
    for (let i = 0; i < cl.length; i++) {
        cl[i].classList.remove(remClass);
    }
}

export function toastSuccess(text: string) {
    toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function toastError(text: string) {
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function toastInfo(text: string, visible: true) {
    toast.info(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function copyToClipboard(text: string) {

    clipboardController(text)
        .then((v) => {
            toast.success('¡Texto copiado!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
        .catch((v) => {
            toast.error('No se pudo copiar', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
}

export function clipboardController(text: string) {
    if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(text)
    } else {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            document.execCommand('copy') ? res(true) : rej();
            textArea.remove();
        });
    }
}

export let viewForm = (id: string) => {
    window.location.href = id;
}

export let allComplete = (elements: HTMLInputElement[]) => {
    let res = true;
    for (let i = 0; i < elements.length; i++) {
        let a = elements[i];
        if (a.value === undefined || a.value === null || a.value === "") {
            res = false;
            break;
        }
    }
    return res;
}

export let post = (to: string, vals: any, headers: any) => {
    const axios = require("axios");

    return new Promise((res, rej) => {
        axios.post((to.substring(0, 8) != "https://" ? "https://solucionesdata.com/" : "") + to, vals, headers).then((resp: { data: any }) => {
            res(resp.data);
        }, (error: any) => {
            console.error(error);
        })
    })
}
/**
 * (e) => {

}
 */

export let getDate = () => {
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + ' ' + time;
    return dateTime;
}